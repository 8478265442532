import React from "react";

interface TimeGridProps {
  startTime: string;
  endTime: string;
  onStartTimeChange: (time: string) => void;
  onEndTimeChange: (time: string) => void;
  errors: {
    startTime?: string;
    endTime?: string;
  };
  clearError: (field: "startTime" | "endTime") => void;
}

const TimeGrid: React.FC<TimeGridProps> = ({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  errors,
  clearError,
}) => {
  const to12Hour = (time24: string) => {
    if (!time24) return "";
    const [hours, minutes] = time24.split(":");
    const hour = parseInt(hours);
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    return `${hour12}:${minutes} ${period}`;
  };

  const to24Hour = (time12: string) => {
    if (!time12) return "";
    const [time, period] = time12.split(" ");
    let [hours, minutes] = time.split(":");
    let hour = parseInt(hours);

    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    return `${hour.toString().padStart(2, "0")}:${minutes}`;
  };

  const generateTimeOptions = () => {
    const options = [];
    // Generate times for AM
    for (let hour = 1; hour <= 12; hour++) {
      options.push(`${hour}:00 AM`);
      options.push(`${hour}:30 AM`);
    }
    // Generate times for PM
    for (let hour = 1; hour <= 12; hour++) {
      options.push(`${hour}:00 PM`);
      options.push(`${hour}:30 PM`);
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  const handleTimeChange = (value: string, type: "startTime" | "endTime") => {
    const time24 = to24Hour(value);
    if (type === "startTime") {
      onStartTimeChange(time24);
    } else {
      onEndTimeChange(time24);
    }
    clearError(type);
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        {/* Start Time */}
        <div>
          <label htmlFor="startTime" className="block text-sm font-medium mb-1">
            Start Time
          </label>
          <select
            id="startTime"
            value={startTime ? to12Hour(startTime) : ""}
            onChange={(e) => handleTimeChange(e.target.value, "startTime")}
            onFocus={() => clearError("startTime")}
            className="mt-1 block w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select start time</option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          {errors.startTime && (
            <p className="mt-1 text-sm text-red-600">{errors.startTime}</p>
          )}
        </div>

        {/* End Time */}
        <div>
          <label htmlFor="endTime" className="block text-sm font-medium mb-1">
            End Time
          </label>
          <select
            id="endTime"
            value={endTime ? to12Hour(endTime) : ""}
            onChange={(e) => handleTimeChange(e.target.value, "endTime")}
            onFocus={() => clearError("endTime")}
            className="mt-1 block w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select end time</option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          {errors.endTime && (
            <p className="mt-1 text-sm text-red-600">{errors.endTime}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeGrid;
