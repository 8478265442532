import React from "react";
import { motion } from "framer-motion";

interface DocumentPreviewProps {
  url: string;
  onClose: () => void;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ url, onClose }) => {
  // Function to determine if the file type can be previewed
  const canPreview = (url: string) => {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'pdf';
  };

  // Function to handle unsupported file types
  const handleUnsupportedFile = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download'; // Set a default filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Trigger download for unsupported files
  if (!canPreview(url)) {
    handleUnsupportedFile();
  }

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      style={{ zIndex: 9999 }} // Set a high z-index
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose} // Close on backdrop click
    >
      <motion.div
        className="bg-white p-6 rounded shadow-md relative" // Increased padding for better layout
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()} // Prevent click from closing when clicking inside
      >
        <button onClick={onClose} className="absolute top-2 right-2 z-10"> {/* Ensure close button is above other elements */}
          Close
        </button>
        {canPreview(url) ? (
          <iframe src={url} width="600" height="500" title="Document Preview" />
        ) : (
          <div className="text-center p-4">
            <p className="text-lg text-red-500">This document has been downloaded.</p>
            <p>Please view it on your local system.</p>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default DocumentPreview;