import React from "react";
import HeaderText from "../components/headerText/HeaderText";
import CreateSchedule from "../components/schedule/CreateSchedule";
import Slots from "../components/schedule/slots/Slots";

const Schedule: React.FC = () => {
  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center py-3">
        <HeaderText text="Schedule" />
        <div className="flex flex-col-reverse space-y-10 space-x-0 md:flex-col lg:space-y-0 lg:space-x-10 lg:flex-row">
          <CreateSchedule />
        </div>
      </section>

      <section>
        <Slots />
      </section>
    </div>
  );
};

export default Schedule;
