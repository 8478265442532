import React from "react";
import { NavLink } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { RxPerson, RxExit } from "react-icons/rx";
import { CiCreditCard1 } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface MobileNavProps {
  handleMobileLinkClick: () => void;
  isMobileNavOpen: boolean;
}

interface LinkItem {
  to: string;
  icon: React.ReactNode;
  label: string;
}

const iconStyle = "inline-block mr-2 text-2xl";

const MobileNavigation: React.FC<MobileNavProps> = ({
  handleMobileLinkClick,
  isMobileNavOpen,
}) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const userEmail = userInfo?.email || "";

  const getNavLinks = (email: string): LinkItem[] => [
    {
      to: `/admin/${email}/dashboard`,
      icon: <MdDashboard className={iconStyle} />,
      label: "Dashboard",
    },
    {
      to: `/admin/${email}/product`,
      icon: <CiCreditCard1 className={iconStyle} />,
      label: "Product",
    },
    {
      to: `/admin/${email}/personnel`,
      icon: <FaUsers className={iconStyle} />,
      label: "Personnel",
    },
    {
      to: `/admin/${email}/report`,
      icon: <TbReport className={iconStyle} />,
      label: "Report",
    },
    {
      to: `/admin/${email}/profile`,
      icon: <RxPerson className={iconStyle} />,
      label: "Profile",
    },
    {
      to: "/",
      icon: <RxExit className={iconStyle} />,
      label: "Logout",
    },
  ];

  const NavItem: React.FC<{ item: LinkItem }> = ({ item }) => (
    <NavLink
      to={item.to}
      className="text-2xl text-gray-800 flex items-center"
      onClick={handleMobileLinkClick}
    >
      {item.icon}
      {item.label}
    </NavLink>
  );

  return (
    <div
      className={`lg:hidden fixed inset-0 bg-white z-40 transition-transform duration-300 ease-in-out ${
        isMobileNavOpen ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="flex flex-col justify-center items-center h-full space-y-8">
        {getNavLinks(userEmail).map((item) => (
          <NavItem key={item.to} item={item} />
        ))}
      </div>
    </div>
  );
};

export default MobileNavigation;