import React, { FC } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SuperAdminRoutes from "./super-admin/Routes/SuperAdminRoutes";
import OnboardingRoutes from "./onboarding/Routes/OnboardingRoutes";
import AdminRoutes from "./admin/routes/AdminRoutes";
import ManagerRoutes from "./manager/routes/ManageRoutes";
import SalesRepRoutes from "./salesRep/routes/SalesRepRoutes";

const App: FC = () => {
  const routes = [
    ...SuperAdminRoutes,
    ...OnboardingRoutes,
    ...AdminRoutes,
    ...ManagerRoutes,
    ...SalesRepRoutes,
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default App;
