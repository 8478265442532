import React, { useState, useEffect } from "react";
import { FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  CustomerData,
  CustomerSelectorProps,
} from "../../schedule/types/types";
import useDebounce from "../../../../hooks/useDebounce";
import accessURL from "../../../../components/utils/access";

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalCustomers: number;
  limit: number;
}

const CustomerSelector = ({
  onCustomerSelect,
  selectedCustomer,
  error,
}: CustomerSelectorProps) => {
  const [customers, setCustomers] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalCustomers: 0,
    limit: 10,
  });
  const debouncedSearch = useDebounce(searchTerm, 300);

  const fetchCustomers = async (pageNum: number, isNewSearch = false) => {
    try {
      setLoading(true);

      const response = await accessURL.get(
        `/customers?page=${pageNum}&limit=${pagination.limit}${
          searchTerm ? `&name=${searchTerm}` : ""
        }`
      );

      if (isNewSearch) {
        setCustomers(response.data.data);
      } else {
        setCustomers((prev) => [...prev, ...response.data.data]);
      }

      setPagination({
        currentPage: pageNum,
        totalPages: Math.ceil(
          response.data.pagination.totalCustomers / pagination.limit
        ),
        totalCustomers: response.data.pagination.totalCustomers,
        limit: pagination.limit,
      });
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchCustomers(1, true);
  }, [searchTerm]);

  const loadMoreCustomers = () => {
    if (pagination.currentPage < pagination.totalPages) {
      fetchCustomers(pagination.currentPage + 1);
    }
  };

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Select Customer
      </label>

      {/* Selected customer display */}
      {selectedCustomer && (
        <div className="mb-2">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm bg-blue-100 text-blue-800">
            {selectedCustomer.name}
            <button
              type="button"
              className="ml-1 text-blue-600 hover:text-blue-800"
              onClick={() => onCustomerSelect(null)}
            >
              ×
            </button>
          </span>
        </div>
      )}

      {/* Dropdown container */}
      <div className="relative">
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
            if (!isOpen && onCustomerSelect) {
              // Clear error when opening dropdown
              onCustomerSelect(selectedCustomer);
            }
          }}
          className="w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        >
          <span className="flex items-center justify-between">
            <span className="block truncate">
              {selectedCustomer ? selectedCustomer.name : "Select customer"}
            </span>
            {isOpen ? (
              <FaChevronUp className="text-gray-400" />
            ) : (
              <FaChevronDown className="text-gray-400" />
            )}
          </span>
        </button>

        {/* Dropdown menu */}
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md border border-gray-300">
            {/* Search input */}
            <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
              <div className="relative">
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md pl-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search customers..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => {
                    if (onCustomerSelect) {
                      // Clear error when focusing search
                      onCustomerSelect(selectedCustomer);
                    }
                  }}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            {/* Customers list */}
            <div className="overflow-auto max-h-72">
              {customers.map((customer) => (
                <div
                  key={customer.id}
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    onCustomerSelect(customer);
                    setIsOpen(false);
                  }}
                >
                  <div className="font-medium">{customer.name}</div>
                  <div className="text-sm text-gray-500">
                    {customer.city}, {customer.state}
                  </div>
                </div>
              ))}

              {/* Load more button */}
              {!loading && pagination.currentPage < pagination.totalPages && (
                <button
                  type="button"
                  onClick={loadMoreCustomers}
                  className="w-full py-2 text-sm text-blue-600 hover:text-blue-800 hover:bg-gray-50 border-t"
                >
                  Load more ({pagination.totalCustomers - customers.length}{" "}
                  remaining)
                </button>
              )}

              {/* Loading indicator */}
              {loading && (
                <div className="p-4 text-center text-gray-500">Loading...</div>
              )}

              {/* Empty state */}
              {!loading && customers.length === 0 && (
                <div className="p-4 text-center text-gray-500">
                  No customers found
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Error message */}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default CustomerSelector;
