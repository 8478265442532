import React, { useState } from "react";
import ReportModal from "./ReportModal";
import { TbReport } from "react-icons/tb";
import { toast } from "react-toastify";
import accessURL from "../../../../components/utils/access";
import { CustomerData } from "../../schedule/types/types";

interface FormData {
  objective: string;
  dateOfVisit: string;
  content: number[];
  products: number[];
  comment: string;
  customer: CustomerData | null;
}

const CreateReport: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleReportSubmit = async (formData: FormData) => {
    setIsLoading(true);
    const response = await accessURL.post("/reports/create", formData);

    if (response.status === 201) {
      toast.success("Report created successfully!");
      setTimeout(() => {
        window.location.reload();
        handleCloseModal();
      }, 2000);
      setIsLoading(false);
    } else {
      toast.error("Failed to create report. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleOpenModal}
        className="flex items-center gap-2 bg-blue-900 text-white px-4 py-2 rounded text-xl"
      >
        <TbReport />
        Create Report
      </button>
      <div className="py-10">
        {isModalOpen && (
          <ReportModal
            onClose={handleCloseModal}
            onSubmit={handleReportSubmit}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default CreateReport;
