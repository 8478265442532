import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import accessURL from "../../../../../../components/utils/access";
import Sort from "../../../sort/Sort";
import Action from "./Action";

interface ManagerData {
  ID: number;
  name: string;
  role: string;
  email: string;
  companyID: string;
  cpa: number | null;
  salesAchieved: number | null;
  cpaGrowthPeriod: number | null;
  salesGrowthPeriod: number | null;
}

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

const Manager: React.FC = () => {
  const [data, setData] = useState<ManagerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchManagers(currentPage, perPage, searchTerm);
  }, [currentPage, perPage, searchTerm]);

  const fetchManagers = async (
    page: number,
    pageSize: number,
    search: string
  ) => {
    setLoading(true);
    try {
      const response = await accessURL.get<{
        data: { managers: ManagerData[]; pagination: PaginationInfo };
      }>(
        `managers?page=${page}&per_page=${pageSize}${
          search ? `&name=${search}` : ""
        }`
      );
      setData(response.data.data.managers);
      setTotalRows(response.data.data.pagination.total);
    } catch (error) {
      console.error("Error fetching managers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns: TableColumn<ManagerData>[] = [
    {
      name: "S/N",
      selector: (row: ManagerData) => row.ID,
      format: (row: ManagerData, index: number) =>
        (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row: ManagerData) => row.name,
    },
    {
      name: "CPA(%)",
      selector: (row: ManagerData) => (row.cpa !== null ? row.cpa : 0),
      width: "100px",
    },
    {
      name: "Sales Achieved(%)",
      selector: (row: ManagerData) =>
        row.salesAchieved !== null ? row.salesAchieved : 0,
      width: "150px",
    },
    {
      name: "CPA Growth Vs Period",
      selector: (row: ManagerData) =>
        row.cpaGrowthPeriod !== null ? row.cpaGrowthPeriod : 0,
      width: "180px",
    },
    {
      name: "Sales Growth Vs Period",
      selector: (row: ManagerData) =>
        row.salesGrowthPeriod !== null ? row.salesGrowthPeriod : 0,
      width: "200px",
    },
    {
      name: "Actions",
      cell: (row: ManagerData) => <Action id={row.ID} name={row.name} />,
      width: "250px",
    },
  ];

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col space-y-4">
      {data.length > 0 && (
        <div className="flex justify-end">
          <Sort onSearch={handleSearch} />
        </div>
      )}

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          striped
          highlightOnHover
          responsive
        />
      </div>
    </div>
  );
};

export default Manager;
