import React from "react";
import Contents from "../components/dashboard/Contents";

const Dashboard: React.FC = () => {
  return (
    <section className="px-10 flex flex-col justify-center space-y-10">
      <header>
        <h1 className="text-3xl text-gray-800 capitalize font-bold">
          dashboard
        </h1>
      </header>
      <Contents />
    </section>
  );
};

export default Dashboard;
