import axios, { AxiosInstance } from "axios";

// https://www.syncai.ltd/api/syncai-crm

export const BaseURL: AxiosInstance = axios.create({
  // baseURL: "http://localhost/syncai-crm",
  baseURL: "https://www.syncai.ltd/api/syncai-crm",
  validateStatus: (status: number): boolean => {
    return status >= 200 && status < 500;
  },
  headers: {
    "Content-Type": "application/json",
  },
});