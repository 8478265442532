import React, { useEffect, useState } from "react";
import accessURL from "../../../../../../../components/utils/access";
import {
  Reports,
  ReportsPagination,
} from "../../../../../../../salesRep/components/schedule/types/types";
import FilterSection from "../../../../../../../salesRep/components/reports/reports/FilterSection";
import Pagination from "../../../../../../../salesRep/components/reports/reports/Pagination";
import ReportCard from "./ReportCard";

interface Filters {
  startDate?: string;
  endDate?: string;
  dateOfVisit?: boolean;
  customer?: boolean;
  customerCategory?: boolean;
  followUpPlan?: boolean;
  customerName?: string;
}

const RepReport: React.FC<{ id: number }> = ({ id }) => {
  const [reports, setReports] = useState<Reports[]>([]);
  const [filters, setFilters] = useState<Filters>({});
  const [pagination, setPagination] = useState<ReportsPagination>({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(false);

  const fetchReports = async () => {
    setLoading(true);

    const params = {
      page: pagination.currentPage,
      limit: pagination.limit,
      startDate: filters.startDate,
      endDate: filters.endDate,
      customerName: filters.customerName,
      dateOfVisit: filters.dateOfVisit,
      customer: filters.customer,
      customerCategory: filters.customerCategory,
      followUpPlan: filters.followUpPlan,
    };

    const response = await accessURL.get(`reports/singleReport?id=${id}`, {
      params,
    });

    setReports(response?.data?.data.reports);
    setPagination(response?.data?.data.pagination);
    setLoading(false);
  };

  useEffect(() => {
    fetchReports();
  }, [pagination.currentPage, filters]);

  const handlePageChange = (pageNumber: number) => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  return (
    <div>
      <FilterSection onFilterChange={handleFilterChange} />
      {loading ? (
        <div className="flex justify-center items-center my-10">
          <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : (
        <>
          {reports.length === 0 ? (
            <div className="flex justify-center items-center my-10 text-gray-500">
              <p>No reports available at the moment. 📄</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {reports.map((report) => (
                <ReportCard key={report.id} report={report} />
              ))}
            </div>
          )}
          <Pagination
            pagination={pagination}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default RepReport;
