import React, { useEffect, useState } from "react";
import DataTable, {
  TableColumn
} from "react-data-table-component";
import accessURL from "../../../../../components/utils/access";
import Btn from "./btn/Btn";
import Sort from "../../../../../admin/components/personnel/sort/Sort";
import { customStyles } from "../../../../../components/utils/utils";

interface ProductData {
  id: number;
  name: string;
  plan: number;
  achieved: number | null;
  pending: number | null;
  most_used_content: string | null;
  least_used_content: string | null;
  companyName: string;
  companyID: string;
  date_created: string;
  date_updated: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalProducts: number;
  limit: number;
}

const Products: React.FC = () => {
  const [data, setData] = useState<ProductData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");

  const columns: TableColumn<ProductData>[] = [
    {
      name: "S/N",
      selector: (row) => row.id,
      format: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Plan",
      selector: (row) => row.plan,
      format: (row) => row.plan.toLocaleString(),
      sortable: true,
    },
    {
      name: "Achieved",
      selector: (row) => row.achieved || 0,
      format: (row) => (row.achieved || 0).toLocaleString(),
      sortable: true,
    },
    {
      name: "Pending",
      selector: (row) => row.pending || 0,
      format: (row) => (row.pending || 0).toLocaleString(),
      sortable: true,
    },
    {
      name: "Most used content",
      selector: (row) => row.most_used_content || "N/A",
      wrap: true,
    },
    {
      name: "Least used content",
      selector: (row) => row.least_used_content || "N/A",
      wrap: true,
    },
    // {
    //   name: "Actions",
    //   cell: (row: ProductData) => <Btn id={row.id} data={row} />,
    //   width: "150px",
    // },
  ];

  const fetchProducts = async (
    page: number,
    pageSize: number,
    search: string
  ) => {
    setLoading(true);
    let url = `products?page=${page}&limit=${pageSize}`;

    if (search) {
      url += `&name=${search}`;
    }

    if (sortField) {
      url += `&sort=${sortField}`;
    }

    const response = await accessURL.get<{
      data: ProductData[];
      pagination: PaginationInfo;
    }>(url);
    setData(response.data.data);
    setTotalRows(response.data.pagination.totalProducts);
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts(currentPage, perPage, searchTerm);
  }, [currentPage, perPage, searchTerm, sortField]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (
    column: TableColumn<ProductData>,
    sortDirection: "asc" | "desc"
  ) => {
    setSortField(`${column.selector}:${sortDirection}`);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };


  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      {data.length > 0 && (
        <div className="flex justify-end my-6">
          <Sort onSearch={handleSearch} />
        </div>
      )}

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        paginationPerPage={perPage}
        striped
        highlightOnHover
        responsive
        persistTableHead
        customStyles={customStyles}
        noDataComponent={
          <div className="p-4 text-center text-gray-500">No products found</div>
        }
      />
    </div>
  );
};

export default Products;
