import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

const getAuthToken = (): string | null => {
  const userString = localStorage.getItem("user");
  if (!userString) {
    return null;
  }

  const user = JSON.parse(userString);
  return user?.token || null;
};

export const accessURL: AxiosInstance = axios.create({
  // baseURL: "http://localhost/syncai-crm",
  baseURL: "https://www.syncai.ltd/api/syncai-crm",
  validateStatus: (status: number): boolean => {
    return status >= 200 && status < 500;
  },
});

accessURL.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getAuthToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

accessURL.interceptors.response.use((response: AxiosResponse) => {
  if (response.status === 401) {
    window.location.href = "/";
  }

  return response;
});

export default accessURL;
