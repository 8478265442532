import React from "react";
import SuperAdminLayout from "../Layout/SuperAdminLayout";
import Dashboard from "../pages/Dashboard";

const SuperAdminRoutes = [
  {
    path: "/administrator",
    element: <SuperAdminLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "admins",
        element: <Dashboard />,
      },
    ],
  },
];
export default SuperAdminRoutes;
