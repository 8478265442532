import React from "react";
import { motion } from "framer-motion";
import { snakyAnimation, fadeIn } from "../../../animations/Animations";

const PricingHeader: React.FC = () => {
  const headerText = "Choose your subscription plan";

  return (
    <header className="flex flex-col justify-center items-center py-10 px-4">
      <div className="text-2xl text-center md:text-start lg:text-3xl xl:text-5xl text-gray-900 flex space-x-2">
        {headerText.split(" ").map((word, i) => (
          <motion.h1
            key={i}
            custom={i}
            variants={snakyAnimation}
            initial="hidden"
            animate="visible"
            className="inline-block"
          >
            {word}
          </motion.h1>
        ))}
      </div>
      <motion.p
        className="max-w-2xl text-center py-6 text-gray-500"
        variants={fadeIn}
        initial="hidden"
        animate="visible"
      >
        Our subscription plans are designed to cater to diverse needs, ensuring
        that every individual can find the perfect balance for their individual
        needs.
      </motion.p>
      <hr className="w-16 mx-auto border-t-2 border-gray-300" />
    </header>
  );
};

export default PricingHeader;
