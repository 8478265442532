import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FieldError, UseFormRegister } from "react-hook-form";

interface PasswordInputProps {
  id: string;
  error?: FieldError;
  register: UseFormRegister<any>;
  validation: any;
  getPasswordBorderColor: () => string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ id, error, register, validation, getPasswordBorderColor }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      <label className="font-semibold mb-1 block text-gray-700">Password</label>
      <input
        type={showPassword ? "text" : "password"}
        id={id}
        {...register(id, validation)}
        className={`w-full px-4 py-2 rounded-md border ${getPasswordBorderColor()} outline-none`}
      />
      <span
        onClick={togglePasswordVisibility}
        className="absolute top-10 right-3 cursor-pointer text-gray-600 text-lg"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
      {error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
    </div>
  );
};

export default PasswordInput;
