import React from "react";
import { FaUser } from "react-icons/fa";

const EmptyState: React.FC = () => (
  <div className="flex flex-col items-center justify-center h-full py-12">
    <div className="bg-gray-100 rounded-full p-6 mb-4">
      <FaUser className="text-gray-400 text-4xl" />
    </div>
    <h3 className="text-xl font-semibold text-gray-600 mb-2">
      No Sales Represantaives Found
    </h3>
    {/* <p className="text-gray-500">Try adjusting your search criteria</p> */}
  </div>
);

export default EmptyState;
