import React, { useState, FormEvent, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { FaSpinner, FaSearch } from "react-icons/fa";
import {
  modalVariants,
  overlayVariants,
} from "../../../../animations/Animations";
import axios from "axios";
import accessURL from "../../../../components/utils/access";

interface ContentUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (formData: FormData) => void;
  isLoading: boolean;
}

interface ProductData {
  id: string;
  name: string;
  description: string;
  price: number;
  category: string;
  inStock: boolean;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalProducts: number;
}

// Custom debounce function
function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

const ContentUploadModal: React.FC<ContentUploadModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  isLoading,
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [selectedProducts, setSelectedProducts] = useState<ProductData[]>([]);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalProducts: 0,
  });
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsFormValid(
      name.trim() !== "" &&
        email.trim() !== "" &&
        phone.trim() !== "" &&
        state.trim() !== "" &&
        city.trim() !== "" &&
        category !== ""
      // selectedProducts.length > 0
    );
  }, [name, email, phone, state, city, category, selectedProducts]);

  const fetchProducts = useCallback(
    async (search: string, page: number = 1) => {
      if (!isOpen) return;
      setIsProductsLoading(true);
      try {
        const response = await accessURL.get<{
          data: ProductData[];
          pagination: PaginationInfo;
        }>(`products?page=${page}&limit=20${search ? `&name=${search}` : ""}`);
        setProducts(response.data.data);
        setPaginationInfo(response.data.pagination);
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products");
      } finally {
        setIsProductsLoading(false);
      }
    },
    [isOpen]
  );

  const debouncedFetchProducts = useCallback(
    debounce((search: string) => fetchProducts(search), 300),
    [fetchProducts]
  );

  useEffect(() => {
    if (isOpen) {
      debouncedFetchProducts(searchTerm);
    }
  }, [isOpen, searchTerm, debouncedFetchProducts]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      toast.error("Please fill all required fields");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("category", category);
    // selectedProducts.forEach((product) =>
    //   formData.append("products[]", product.id)
    // );
    onUpload(formData);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={overlayVariants}
    >
      <motion.div
        className="bg-white p-6 rounded-lg w-full max-w-2xl relative z-60 max-h-[90vh] overflow-y-auto"
        variants={modalVariants}
      >
        <h2 className="text-3xl font-bold mb-4 py-3">Add New Customer</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                id="phone"
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <input
                id="state"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              >
                <option value="">Select a category</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
              </select>
            </div>
          </div>

          {/* <div>
            <label
              htmlFor="products"
              className="block text-sm font-medium text-gray-700"
            >
              Products
            </label>
            <div className="relative">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mt-1 block w-full px-4 pr-10 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="mt-2 max-h-40 overflow-y-auto border border-gray-200 rounded-md">
              {isProductsLoading ? (
                <div className="flex justify-center items-center h-20">
                  <FaSpinner className="animate-spin text-blue-500" />
                </div>
              ) : products.length > 0 ? (
                products.map((product) => (
                  <div
                    key={product.id}
                    className="flex items-center justify-between p-2 hover:bg-gray-100"
                  >
                    <div>
                      <h1 className="font-medium">{product.name}</h1>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleProductSelect(product)}
                      className="text-blue-500 hover:underline"
                    >
                      Select
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-center py-2 text-gray-500">
                  No products found
                </p>
              )}
            </div>
            {paginationInfo.totalPages > 1 && (
              <div className="mt-2 flex justify-between items-center">
                <button
                  type="button"
                  onClick={() =>
                    fetchProducts(searchTerm, paginationInfo.currentPage - 1)
                  }
                  disabled={paginationInfo.currentPage === 1}
                  className="text-blue-500 hover:underline disabled:text-gray-300"
                >
                  Previous
                </button>
                <span>{`Page ${paginationInfo.currentPage} of ${paginationInfo.totalPages}`}</span>
                <button
                  type="button"
                  onClick={() =>
                    fetchProducts(searchTerm, paginationInfo.currentPage + 1)
                  }
                  disabled={
                    paginationInfo.currentPage === paginationInfo.totalPages
                  }
                  className="text-blue-500 hover:underline disabled:text-gray-300"
                >
                  Next
                </button>
              </div>
            )}
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-700">
                Selected Products
              </h4>
              {selectedProducts.length === 0 ? (
                <p className="text-gray-500">No products selected</p>
              ) : (
                <ul className="mt-1 space-y-2 max-h-40 overflow-y-auto">
                  {selectedProducts.map((product) => (
                    <li
                      key={product.id}
                      className="flex items-center justify-between bg-gray-100 p-2 rounded"
                    >
                      <div>
                        <h1 className="font-medium">{product.name}</h1>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleProductRemove(product.id)}
                        className="text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div> */}

          <div className="flex justify-end">
            <button
              type="submit"
              className={`px-4 py-2 font-semibold text-white rounded-md ${
                isFormValid
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={!isFormValid || isLoading}
            >
              {isLoading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                "Add Customer"
              )}
            </button>
          </div>
        </form>

        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-red-500 text-4xl hover:text-red-700"
        >
          &times;
        </button>
      </motion.div>
    </motion.div>
  );
};

export default ContentUploadModal;
