import React from "react";
import { ReportsPagination } from "../../schedule/types/types";

interface PaginationProps {
  pagination: ReportsPagination;
  handlePageChange: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  pagination,
  handlePageChange,
}) => {
  const { currentPage, totalPages } = pagination;

  return (
    <div className="flex justify-center mt-4">
      {Array.from({ length: totalPages }, (_, index) => index + 1).map(
        (pageNumber) => (
          <button
            key={pageNumber}
            className={`mx-2 px-4 py-2 rounded ${
              pageNumber === currentPage
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        )
      )}
    </div>
  );
};

export default Pagination;
