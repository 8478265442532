import React from "react";
import HeaderText from "../components/headerText/HeaderText";
import CreateReport from "../components/reports/create/CreateReport";
import ReportsComponent from "../components/reports/reports";

const Reports: React.FC = () => {
  return (
    <section>
      <div className="p-4 md:p-6 lg:p-8 xl:p-14">
        <section className="flex flex-col lg:flex-row justify-between items-center py-3">
          <HeaderText text="Reports" />
          <div className="flex flex-col-reverse space-y-10 space-x-0 md:flex-col lg:space-y-0 lg:space-x-10 lg:flex-row">
            <CreateReport />
          </div>
        </section>

        <section>
          <ReportsComponent />
        </section>
      </div>
    </section>
  );
};

export default Reports;
