import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectToOnboarding: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>RedirectToOnboarding</div>;
};

export default RedirectToOnboarding;
