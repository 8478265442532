import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight, MdExpandMore } from "react-icons/md";

interface CalendarProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
  className?: string;
}

const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  onChange,
  className = "",
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(() => {
    const storedMonthYear = localStorage.getItem("currentMonthYear");
    if (storedMonthYear) {
      const [year, month] = storedMonthYear.split("-").map(Number);
      return new Date(year, month - 1);
    }
    return new Date();
  });

  const [isMonthSelectorOpen, setIsMonthSelectorOpen] = useState(false);

  useEffect(() => {
    const monthYearString = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }`;
    localStorage.setItem("currentMonthYear", monthYearString);
  }, [currentDate]);

  useEffect(() => {
    onChange(selectedDate);
  }, [onChange, selectedDate]);

  const today: Date = new Date();

  const getDaysInMonth = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date: Date): number => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const generateDays = (): (number | null)[] => {
    const daysInMonth: number = getDaysInMonth(currentDate);
    const firstDayOfMonth: number = getFirstDayOfMonth(currentDate);
    const days: (number | null)[] = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    return days;
  };

  const previousMonth = (): void => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const nextMonth = (): void => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  const handleMonthSelect = (monthIndex: number): void => {
    setCurrentDate(new Date(currentDate.getFullYear(), monthIndex));
    setIsMonthSelectorOpen(false);
  };

  const isToday = (day: number | null): boolean => {
    if (!day) return false;
    const compareDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    return compareDate.toDateString() === today.toDateString();
  };

  const isSelected = (day: number | null): boolean => {
    if (!day) return false;
    const compareDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    return compareDate.toDateString() === selectedDate.toDateString();
  };

  const handleDateSelect = (day: number | null): void => {
    if (!day) return;
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    onChange(newDate); // Update the selected date
  };

  const weekDays: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div
      className={`w-full mx-auto bg-white rounded-lg shadow-lg p-4 ${className}`}
    >
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={previousMonth}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Previous month"
        >
          <MdChevronLeft className="w-5 h-5" />
        </button>
        <div className="relative">
          <button
            onClick={() => setIsMonthSelectorOpen(!isMonthSelectorOpen)}
            className="flex items-center gap-1 px-3 py-1 rounded-md hover:bg-gray-100 transition-colors"
          >
            <h2 className="text-lg font-semibold">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </h2>
            <MdExpandMore className="w-5 h-5" />
          </button>
          {isMonthSelectorOpen && (
            <div className="absolute top-full mt-1 w-48 bg-white shadow-lg rounded-md border border-gray-200 py-1 z-10">
              <div className="grid grid-cols-3 gap-1 p-2">
                {months.map((month, index) => (
                  <button
                    key={month}
                    onClick={() => handleMonthSelect(index)}
                    className={`p-2 text-sm rounded hover:bg-gray-100 transition-colors ${
                      currentDate.getMonth() === index
                        ? "bg-blue-500 text-white hover:bg-blue-600"
                        : ""
                    }`}
                  >
                    {month.slice(0, 3)}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <button
          onClick={nextMonth}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Next month"
        >
          <MdChevronRight className="w-5 h-5" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1 mb-2">
        {weekDays.map((day) => (
          <div
            key={day}
            className="text-center text-sm font-medium text-gray-600 py-2"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {generateDays().map((day, index) => (
          <button
            key={index}
            onClick={() => handleDateSelect(day)}
            disabled={!day}
            className={`aspect-square p-2 text-sm rounded-lg transition-colors ${
              !day ? "cursor-default" : "hover:bg-gray-100"
            } ${
              isToday(day) ? "bg-blue-100 text-blue-600 font-semibold" : ""
            } ${
              isSelected(day) ? "bg-blue-500 text-white hover:bg-blue-600" : ""
            }`}
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
