import React from "react";
import { motion } from "framer-motion";
import {
  overlayVariants,
  modalVariants,
} from "../../../../../../animations/Animations";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
        >
          <motion.div
            className="bg-white p-6 rounded-lg w-full max-w-2xl relative z-60"
            variants={modalVariants}
          >
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-2xl border border-blue-900 rounded-full h-10 w-10 flex items-center justify-center" // h-10 and w-10 ensure it’s a circle
            >
              &times;
            </button>

            {children}
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default Modal;
