import React, { useState, useEffect } from "react";
import { FaSearch, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { PaginationInfo } from "../../schedule/types/types";
import accessURL from "../../../../components/utils/access";

interface ProductSelectorProps {
  onProductsChange: (products: number[]) => void;
  selectedProducts: number[];
  error?: string;
}

const ProductSelector: React.FC<ProductSelectorProps> = ({
  onProductsChange,
  selectedProducts,
  error,
}) => {
  const [products, setProducts] = useState<{ id: number; name: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalProducts: 0,
    limit: 10,
  });

  const fetchProducts = async (pageNum: number, isNewSearch = false) => {
    try {
      setLoading(true);
      const response = await accessURL.get(
        `/products?page=${pageNum}&limit=${pagination.limit}${
          searchTerm ? `&name=${searchTerm}` : ""
        }`
      );

      if (isNewSearch) {
        setProducts(response.data.data);
      } else {
        setProducts((prev) => [...prev, ...response.data.data]);
      }

      setPagination({
        currentPage: pageNum,
        totalPages: Math.ceil(
          response.data.pagination.totalProducts / pagination.limit
        ),
        totalProducts: response.data.pagination.totalProducts,
        limit: pagination.limit,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchProducts(1, true);
  }, [searchTerm]);

  const loadMoreProducts = () => {
    if (pagination.currentPage < pagination.totalPages) {
      fetchProducts(pagination.currentPage + 1);
    }
  };

  const toggleProduct = (productId: number) => {
    const newSelectedProducts = selectedProducts.includes(productId)
      ? selectedProducts.filter((id) => id !== productId)
      : [...selectedProducts, productId];
    onProductsChange(newSelectedProducts);
  };

  const selectedProductNames = products.filter((product) =>
    selectedProducts.includes(product.id)
  );

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Select Products
      </label>
      <div className="mb-2 flex flex-wrap gap-2">
        {selectedProductNames.map((product) => (
          <span
            key={product.id}
            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm bg-blue-100 text-blue-800"
          >
            {product.name}
            <button
              type="button"
              className="ml-1 text-blue-600 hover:text-blue-800"
              onClick={() => toggleProduct(product.id)}
            >
              ×
            </button>
          </span>
        ))}
      </div>

      <div className="relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`w-full bg-white border rounded-md py-2 px-3 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 ${
            error ? "border-red-500" : "border-gray-300"
          }`}
        >
          <span className="flex items-center justify-between">
            <span className="block truncate">
              {selectedProducts.length === 0
                ? "Select products"
                : `${selectedProducts.length} product(s) selected`}
            </span>
            {isOpen ? (
              <FaChevronUp className="text-gray-400" />
            ) : (
              <FaChevronDown className="text-gray-400" />
            )}
          </span>
        </button>

        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}

        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md border border-gray-300">
            <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
              <div className="relative">
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md pl-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search products..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            <div className="overflow-auto max-h-72">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={() => toggleProduct(product.id)}
                >
                  <input
                    type="checkbox"
                    checked={selectedProducts.includes(product.id)}
                    readOnly
                    className="mr-2"
                  />
                  <span>{product.name}</span>
                </div>
              ))}

              {!loading && pagination.currentPage < pagination.totalPages && (
                <button
                  type="button"
                  onClick={loadMoreProducts}
                  className="w-full py-2 text-sm text-blue-600 hover:text-blue-800 hover:bg-gray-50 border-t"
                >
                  Load more ({pagination.totalProducts - products.length}{" "}
                  remaining)
                </button>
              )}

              {loading && (
                <div className="p-4 text-center text-gray-500">Loading...</div>
              )}
              {!loading && products.length === 0 && (
                <div className="p-4 text-center text-gray-500">
                  No products found
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
