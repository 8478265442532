import React, { useState, useEffect, useRef } from "react";
import { FiFilter } from "react-icons/fi";

interface FilterOption {
  id: string;
  label: string;
}

const filterOptions: FilterOption[] = [
  { id: "name", label: "Name" },
  { id: "state", label: "State" },
  { id: "city", label: "City" },
  { id: "category", label: "Category" },
];

interface FiltersProps {
  setFilters: (filters: string[]) => void;
}

const Filters: React.FC<FiltersProps> = ({ setFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const filterRef = useRef<HTMLDivElement>(null);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (filterId: string) => {
    setSelectedFilters((prev) => {
      const newFilters = prev.includes(filterId)
        ? prev.filter((id) => id !== filterId)
        : [...prev, filterId];

      console.log("Selected filters:", newFilters);
      setFilters(newFilters);
      return newFilters;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative inline-block text-left py-3 md:py-0"
      ref={filterRef}
    >
      <button
        className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        onClick={toggleFilter}
      >
        <FiFilter className="w-6 h-6" />
        <span className="tex-xl">Filter</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-2 space-y-2">
          {filterOptions.map((option) => (
            <label
              key={option.id}
              className="flex items-center space-x-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id={option.id}
                checked={selectedFilters.includes(option.id)}
                onChange={() => handleFilterChange(option.id)}
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <span className="text-sm text-gray-700">{option.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filters;
