import React from "react";
import Header from "../header/Header";
import PricingComp from "../components/pricing/PricingComp";

const Pricing: React.FC = () => {
  return (
    <section>
      <Header />
      <PricingComp />
    </section>
  );
};

export default Pricing;
