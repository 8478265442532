import React from "react";
import { useParams } from "react-router-dom";
import Det from "../components/reports/single_reports/salesRepDet/Det";
import RepReport from "../components/reports/single_reports/salesRepDet/rep_report/RepReport";

const SingleReports: React.FC = () => {
  const { id } = useParams();
  return (
    <section className="py-10 px-8">
      <Det id={id ? Number(id) : 0} />
      <RepReport id={id ? Number(id) : 0} />
    </section>
  );
};

export default SingleReports;
