import React from "react";

const InfoSection: React.FC = () => {
  return (
    <section className="max-w-4xl mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-gray-800 text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 text-center">
        Make Payments into Any of Our Accounts
      </h1>
      
      <div className="space-y-6 mb-8">
        <AccountDetails 
          bank="First Bank"
          accountName="SyncAI Technologies"
          accountNumber="1234567890"
        />
        <AccountDetails 
          bank="Second Bank"
          accountName="SyncAI Solutions"
          accountNumber="0987654321"
        />
      </div>
      
      <p className="text-gray-600 text-sm sm:text-base mb-4">
        After making a payment, please contact our support team at{' '}
        <a href="mailto:support@syncai.com" className="text-blue-600 hover:underline">
          support@syncai.com
        </a>
        {' '}with your payment receipt and any other relevant details.
      </p>
    </section>
  );
};

const AccountDetails: React.FC<{
  bank: string;
  accountName: string;
  accountNumber: string;
}> = ({ bank, accountName, accountNumber }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <p className="font-semibold text-lg mb-2">{bank}</p>
    <p>
      <span className="font-medium">Account Name:</span> {accountName}
    </p>
    <p>
      <span className="font-medium">Account Number:</span> {accountNumber}
    </p>
  </div>
);

export default InfoSection;