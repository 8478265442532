import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdDashboard } from "react-icons/md";
import { RxPerson, RxExit } from "react-icons/rx";
import Logo from "../../assets/Logo.svg";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation/Navigation";
import Large from "../components/headers/Large";

const SuperAdminLayout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);

  const toggleSidebar = (): void => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMobileNav = (): void => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleMobileLinkClick = (): void => {
    setIsMobileNavOpen(false);
  };

  return (
    <main className="flex">
      {/* Sidebar (for large screens) */}
      <section
        className={`hidden lg:block ${
          isSidebarOpen ? "w-[15%]" : "w-16"
        } text-white transition-all duration-300 ease-in-out flex-shrink-0 h-screen shadow-md border-r border-gray-400 fixed top-0 left-0`}
      >
        <div
          className={`px-4 flex items-center justify-between py-10 ${
            isSidebarOpen ? "border-b border-gray-400" : ""
          }`}
        >
          <button
            className="text-white text-2xl focus:outline-none"
            onClick={toggleSidebar}
          >
            <RxHamburgerMenu className="text-gray-800 text-xl lg:text-2xl md:text-4xl" />
          </button>

          {isSidebarOpen && (
            <img
              src={Logo}
              alt="syncai logo"
              className="ml-4 h-8 w-auto lg:ml-4 mt-2 lg:mt-0"
            />
          )}
        </div>

        <section className="h-full">
          <Navigation isSidebarOpen={isSidebarOpen} />
        </section>
      </section>

      {/* Header (for mobile with hamburger icon) */}
      <header className="lg:hidden bg-white shadow-md p-4 fixed top-0 left-0 w-full flex justify-between items-center z-50">
        <button className="text-3xl text-gray-800" onClick={toggleMobileNav}>
          <RxHamburgerMenu />
        </button>
        <img src={Logo} alt="syncai logo" className="h-8 w-auto" />
      </header>

      {/* Mobile Navigation */}
      <div 
        className={`lg:hidden fixed inset-0 bg-white z-40 transition-transform duration-300 ease-in-out ${
          isMobileNavOpen ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="flex flex-col justify-center items-center h-full space-y-8">
          <NavLink
            to="/administrator/dashboard"
            className="text-2xl text-gray-800"
            onClick={handleMobileLinkClick}
          >
            <MdDashboard className="inline-block mr-2" /> Dashboard
          </NavLink>
          <NavLink
            to="/administrator/admins"
            className="text-2xl text-gray-800"
            onClick={handleMobileLinkClick}
          >
            <RxPerson className="inline-block mr-2" /> Admins
          </NavLink>
          <NavLink
            to="/profile"
            className="text-2xl text-gray-800"
            onClick={handleMobileLinkClick}
          >
            <RxPerson className="inline-block mr-2" /> Profile
          </NavLink>
          <NavLink
            to="/logout"
            className="text-2xl text-gray-800"
            onClick={handleMobileLinkClick}
          >
            <RxExit className="inline-block mr-2" /> Logout
          </NavLink>
        </div>
      </div>

      {/* Content */}
      <section
        className={`flex-1 bg-gray-100/10 w-full lg:w-[85%] lg:ml-[15%] transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "lg:ml-[15%]" : "lg:ml-16"
        }`}
      >
        <header className="bg-white px-4 py-8 border-b border-gray-400 shadow-sm lg:block hidden">
          <Large />
        </header>
        <div className="pt-10 w-full">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default SuperAdminLayout;