import React from "react";
import Cards from "./cards/Cards";
// import CreateAdmin from "./create_admin/CreateAdmin";
// import Analytics from "./analytics/Analytics";

const Contents: React.FC = () => {
  return (
    <section className="space-y-6">
      <Cards />
      <section className="flex flex-col lg:flex-row gap-6">
        {/* <div className="w-full lg:w-3/5">
          <CreateAdmin />
        </div> */}
        {/* <div className="w-full lg:w-2/5">
          <Analytics />
        </div> */}
      </section>
    </section>
  );
};

export default Contents;