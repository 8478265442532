import React, { useState } from "react";
import Modal from "../addProducts/Modal";
import { FaSpinner } from "react-icons/fa";
import accessURL from "../../../../../../components/utils/access";
import { toast } from "react-toastify";

interface ProductData {
  id: number;
  name: string;
  plan: number;
  achieved: number | null;
  pending: number | null;
  most_used_content: string | null;
  least_used_content: string | null;
  companyName: string;
  companyID: string;
  date_created: string;
  date_updated: string;
}

interface BtnProps {
  id: number;
  data: ProductData;
}

interface FormData {
  productName: string;
  plan: string;
}

const Btn: React.FC<BtnProps> = ({ id, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    productName: data.name,
    plan: data.plan.toString(),
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({ productName: data.name, plan: data.plan.toString() });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const isFormValid =
    formData.productName.trim() !== "" && parseInt(formData.plan) > 0;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsLoading(true);

    const updatedData = {
      id,
      name: formData.productName,
      plan: parseInt(formData.plan),
    };

    const response = await accessURL.post("/products/update", updatedData);

    if (response.status === 200) {
      toast.success("Product updated successfully!");

      setTimeout(() => {
        handleCloseModal();
        window.location.reload();
      }, 2000);
    }

    setIsLoading(false);
  };

  const handleDeleteManager = async () => {
    const response = await accessURL.post(`/products/delete?id=${id}`);

    if (
      response.data &&
      response.data.status === "success" &&
      response.data.code === 200
    ) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <>
      <div className="flex space-x-4">
        <button
          onClick={handleOpenModal}
          className="bg-blue-900 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
        >
          Update
        </button>

        <button
          onClick={() => setShowConfirmModal(true)}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
        >
          Delete
        </button>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="text-2xl font-bold mb-4 py-4">Update Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="productName"
              className="block mb-2 font-semi-bold text-lg"
            >
              Product name
            </label>
            <input
              type="text"
              id="productName"
              value={formData.productName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="plan" className="block mb-2 font-semi-bold text-lg">
              Plan
            </label>
            <input
              type="number"
              id="plan"
              value={formData.plan}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
              min="1"
            />
          </div>
          <div className="flex justify-center gap-2">
            <button
              type="button"
              onClick={handleCloseModal}
              className="px-4 py-2 border border-blue-900 rounded w-full hover:bg-blue-900 hover:text-white hover:ease-in hover:transition-all"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-3 border bg-blue-900 text-white rounded w-full hover:bg-blue-800 hover:ease-in hover:transition-all flex items-center justify-center ${
                !isFormValid && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isLoading || !isFormValid}
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                </>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </form>
      </Modal>

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
            <p className="mb-4">
              This action is irreversible. Do you understand?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteManager}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg z-50">
          {showSuccessModal
            ? "Deleted successfully"
            : "Manager assigned successfully"}
        </div>
      )}
    </>
  );
};

export default Btn;
