import React from "react";
import { GrStatusGood } from "react-icons/gr";
import Line from "../../../../assets/line.svg";
import { Link } from "react-router-dom";

interface PricingCardProps {
  title: string;
  description: string;
  features: string[];
  buttonText: string;
  monthlyPrice?: string;
  annualPrice?: string;
  discount?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  description,
  features,
  buttonText,
  monthlyPrice,
  annualPrice,
  discount,
}) => {
  return (
    <div className="group">
      <div className="bg-gray-100 rounded-lg p-6 h-full transition-all duration-300 transform group-hover:scale-105 group-hover:bg-black group-hover:text-white">
        <h2 className="text-xl font-bold mb-2">{title}</h2>
        <p className="text-sm mb-4 text-gray-500">{description}</p>
        <hr className="w-full h-0.5 bg-gray-300 my-3" />
        <h3 className="font-semibold text-sm mb-2 md:text-lg">
          Feature / benefits
        </h3>
        <ul className="space-y-2 mb-6 text-sm">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start space-x-2">
              <GrStatusGood className="w-5 h-5 text-gray-500 flex-shrink-0 mt-1 group-hover:text-blue-300" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
        {monthlyPrice && annualPrice && (
          <div className="flex justify-between items-center mb-4 text-sm">
            <div>
              <p>Monthly Bill</p>
              <p className="font-bold">{monthlyPrice}</p>
            </div>
            <div className="text-center">
              <p className="group-hover:text-green-300">Save {discount}</p>
              <img src={Line} alt="Discount line" className="w-full mt-1" />
            </div>
            <div className="text-right">
              <p>Annual Bill</p>
              <p className="font-bold">{annualPrice}</p>
            </div>
          </div>
        )}
        <Link
          to="/pricing/info"
          className="w-full py-2 px-8 flex justify-center items-center rounded-md bg-blue-900 text-white group-hover:bg-white group-hover:text-gray-900 transition-colors duration-300"
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default PricingCard;
