import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderText from "../components/headerText/HeaderText";
import AddContent from "../components/products/display/contents/addContents.tsx/AddContent";
import AddProduct from "../components/products/display/products/addProducts/AddProduct";
import Tabs from "../components/products/tabs/Tab";

const Product: React.FC = () => {
  const [role, setRole] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleParam = searchParams.get("role");
    setRole(roleParam || "");
  }, [location]);

  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text={role === "contents" ? "Contents" : "Products"} />
      </section>

      <section>
        <Tabs />
      </section>
    </div>
  );
};

export default Product;
