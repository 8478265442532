import React from "react";
import { FaUser, FaArrowRight } from "react-icons/fa";
import { SalesRepData } from "../../../salesRep/components/schedule/types/types";

interface SalesRepCardProps {
  rep: SalesRepData;
  onViewReport: (name: string, id: number) => void;
}

const SalesRepCard: React.FC<SalesRepCardProps> = ({ rep, onViewReport }) => (
  <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
    <div className="p-6 flex flex-col items-center">
      <div className="bg-blue-100 p-4 rounded-full mb-4">
        <FaUser className="text-blue-500 text-3xl" />
      </div>
      <h3 className="text-lg font-semibold text-gray-800 text-center mb-2">
        {rep.name}
      </h3>
      <button
        onClick={() => onViewReport(rep.name, rep.ID)}
        className="mt-4 flex items-center justify-center w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md transition-colors duration-300"
      >
        <span className="mr-2">View Reports</span>
        <FaArrowRight className="text-sm" />
      </button>
    </div>
  </div>
);

export default SalesRepCard;
