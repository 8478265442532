import React, { useState, useEffect } from "react";
import accessURL from "../../../../../components/utils/access";
import ContentItem from "./ContentItem";
import DocumentPreview from "./DocumentPreview";
import "./scrollbar.css";

type Content = {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
};

type ContentWithPreview = Content & {
  openPreview: (filePath: string) => void;
};

const Contents: React.FC = () => {
  const [contents, setContents] = useState<ContentWithPreview[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchContents = async (page = 1, query: string = "") => {
    setIsLoading(true);
    try {
      const response = await accessURL.get(
        `contents?page=${page}${query ? `&name=${query}` : ""}`
      );
      const fetchedContents: ContentWithPreview[] = response.data.data.map(
        (item: Content) => ({
          ...item,
          openPreview: (filePath: string) => openPreview(filePath),
        })
      );
      setContents(fetchedContents);
      setCurrentPage(response.data.pagination.currentPage);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching contents:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchContents();
  }, []);

  const handlePageChange = (page: number) => {
    fetchContents(page);
  };

  const openPreview = (filePath: string) => {
    setPreviewUrl(filePath);
  };

  const closePreview = () => {
    setPreviewUrl(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (contents.length === 0) {
    return <div>No contents found</div>;
  }

  const handleSearchSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fetchContents(1, searchQuery);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="space-y-4">
      <div className="relative flex">
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
        />
        <button
          type="button"
          onClick={handleSearchSubmit}
          className="bg-blue-500 text-white px-6 py-2 rounded-r-md hover:bg-blue-600 transition duration-200 ease-in-out"
        >
          Search
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 max-h-[800px] overflow-y-auto  scrollbar-custom sm:grid-cols-2 lg:grid-cols-3">
        {contents.map((content, index) => (
          <ContentItem key={content.id} content={content} index={index} />
        ))}
      </div>
      {previewUrl && (
        <DocumentPreview url={previewUrl} onClose={closePreview} />
      )}
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Contents;
