import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { motion } from "framer-motion";
import { cardContainerVariants } from "../../../../../animations/Animations";
// import UpdateContents from "./updateContents/UpdateContents";

const getFileFormat = (fileType: string): string => {
  if (fileType.includes("pdf")) return "PDF";
  if (fileType.includes("word")) return "DOCX";
  if (fileType.includes("sheet") || fileType.includes("excel")) return "XLSX";
  if (fileType.includes("presentation") || fileType.includes("powerpoint"))
    return "PPTX";
  return "Unknown";
};

type Content = {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
};

interface ContentItemProps {
  content: Content & { openPreview: (filePath: string) => void };
  index: any;
}

const ContentItem: React.FC<ContentItemProps> = ({ content, index }) => {
  return (
    <motion.div
      className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm"
      variants={cardContainerVariants}
      initial="hidden"
      animate="visible"
      custom={index}
    >
      <div className="flex justify-between items-start mb-2 py-4">
        <h3 className="text-lg font-semibold capitalize md:text-xl">
          {content.name}
        </h3>
        <button className="p-1 rounded-full hover:bg-gray-100">
          <FiMoreVertical className="h-4 w-4 text-gray-500" />
        </button>
      </div>
      <p className="text-sm text-gray-600 mb-3">{content.description}</p>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-xs text-gray-500">Purpose</p>
          <p className="text-sm">{content.purpose}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Format</p>
          <p className="text-sm text-red-500">
            {getFileFormat(content.file_type || "")}
          </p>
        </div>
      </div>
      <div className="flex space-x-2 py-2">
        <button
          className="flex-1 bg-white text-blue-600 border border-blue-600 py-1 px-4 rounded"
          onClick={() => content.openPreview(content.file_path!)}
        >
          View
        </button>

        {/* <UpdateContents Content={content} /> */}
      </div>
    </motion.div>
  );
};

export default ContentItem;
