import React from "react";
import { FieldError, UseFormRegister } from "react-hook-form";

interface TermsCheckboxProps {
  error?: FieldError;
  register: UseFormRegister<any>;
  setTerms: (accepted: boolean) => void;
}

const TermsCheckbox: React.FC<TermsCheckboxProps> = ({ error, register, setTerms }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(event.target.checked);
  };

  return (
    <div>
      <label className="flex items-center text-gray-700">
        <input
          type="checkbox"
          {...register("termsAccepted", {
            required: "You must accept the terms and conditions.",
          })}
          className="mr-2"
          onChange={handleCheckboxChange}
        />
        <span>
          I agree to the
          <a href="/terms" className="text-blue-500"> Terms of Service</a>{" "}
          and
          <a href="/privacy" className="text-blue-500"> Privacy Policy</a>.
        </span>
      </label>
      {error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
    </div>
  );
};

export default TermsCheckbox;