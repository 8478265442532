import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "../../../components/utils/utils";
import accessURL from "../../../components/utils/access";
import Actions from "./actions/Actions";

interface CustomerData {
  id: number;
  name: string;
  state: string;
  city: string;
  phone: string;
  email: string;
  category: string;
  companyID: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalCustomers: number;
  limit: number;
}

interface CustomersProps {
  filters: string[];
}

const Customers: React.FC<CustomersProps> = ({ filters }) => {
  const [data, setData] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortFields, setSortFields] = useState<string[]>([]);

  useEffect(() => {
    setSortFields(filters);
  }, [filters]);

  const fetchCustomers = async (
    page: number,
    pageSize: number,
    search: string
  ) => {
    setLoading(true);
    let url = `customers?page=${page}&limit=${pageSize}`;

    if (search) {
      url += `&name=${search}`;
    }

    if (sortFields.length > 0) {
      url += `&sort=${sortFields.join(",")}`;
    }

    try {
      const response = await accessURL.get<{
        data: CustomerData[];
        pagination: PaginationInfo;
      }>(url);
      setData(response.data.data);
      setTotalRows(response.data.pagination.totalCustomers);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(currentPage, perPage, searchTerm);
  }, [currentPage, perPage, searchTerm, sortFields]);

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (
    column: TableColumn<CustomerData>,
    sortDirection: string
  ) => {
    const field = column.selector?.toString() || "";
    if (field) {
      setSortFields((prevFields) => {
        const newFields = prevFields.filter(
          (f) => f !== field && f !== `-${field}`
        );
        newFields.unshift(sortDirection === "asc" ? field : `-${field}`);
        return newFields;
      });
    }
  };

  const getCategoryStyle = (category: string) => {
    switch (category.toUpperCase()) {
      case "A":
        return "bg-green-500 text-white font-bold py-1 px-2 rounded";
      case "B":
        return "bg-yellow-500 text-white font-bold py-1 px-2 rounded";
      case "C":
        return "bg-black text-white font-bold py-1 px-2 rounded";
      default:
        return "bg-gray-500 text-white font-bold py-1 px-2 rounded";
    }
  };

  const columns: TableColumn<CustomerData>[] = [
    {
      name: "S/N",
      selector: (row) => row.id,
      format: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "270px",
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      width: "170px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "270px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      width: "170px",
      cell: (row) => (
        <span className={getCategoryStyle(row.category)}>{row.category}</span>
      ),
    },
    {
      name: "Actions",
      width: "270px",
      cell: (row) => (
        <div>
          <Actions id={row.id} name={row.name} customerData={row} />
        </div>
      ),
    },
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="flex justify-end my-6">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="p-2 border rounded"
        />
      </div>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        paginationPerPage={perPage}
        striped
        highlightOnHover
        responsive
        persistTableHead
        customStyles={customStyles}
        noDataComponent={
          <div className="p-4 text-center text-gray-500">
            No Customers found
          </div>
        }
      />
    </div>
  );
};

export default Customers;
