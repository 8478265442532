import React, { useState } from "react";
import { motion } from "framer-motion";
import { Reports } from "../../schedule/types/types";
import {
  LuCalendarDays,
  LuUser,
  LuTarget,
  LuPackage,
  LuClipboard,
  LuMessageSquare,
  LuUserCheck,
  LuBuilding,
  LuClock,
  LuX,
  LuChevronRight,
  LuSave,
  LuCalendarClock,
  LuClipboardEdit,
} from "react-icons/lu";
import accessURL from "../../../../components/utils/access";
import { toast } from "react-toastify";
import {
  containerVariants,
  listItemVariants,
  modalVariants,
} from "../../../../animations/Animations";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  report: Reports | null;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, report }) => {
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [editedComment, setEditedComment] = useState("");
  const [loading, setLoading] = useState(false);

  if (!isOpen || !report) return null;

  const products =
    typeof report.product === "string"
      ? JSON.parse(report.product)
      : report.product;
  const contents =
    typeof report.content === "string"
      ? JSON.parse(report.content)
      : report.content;

  const categoryColor = (category: string) => {
    switch (category) {
      case "A":
        return "bg-emerald-50 text-emerald-700 ring-emerald-600/20";
      case "B":
        return "bg-amber-50 text-amber-700 ring-amber-600/20";
      case "C":
        return "bg-rose-50 text-rose-700 ring-rose-600/20";
      default:
        return "bg-gray-50 text-gray-700 ring-gray-600/20";
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleEditComment = () => {
    setEditedComment(report.comment);
    setIsEditingComment(true);
  };

  const handleSaveComment = async () => {
    setLoading(true);
    const response = await accessURL.post("/reports/update", {
      comment: editedComment,
      id: report.id,
    });

    setIsEditingComment(false);

    if (response.status === 200) {
      toast.success("Comment updated successfully!");

      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Failed to update comment.");
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={modalVariants}
      className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex justify-center items-center z-50 p-4 animate-in fade-in duration-200"
    >
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bg-white rounded-2xl shadow-xl w-full max-w-3xl max-h-[90vh] overflow-hidden"
      >
        {/* Header */}
        <motion.div
          className="border-b border-gray-100 p-6 sticky top-0 bg-white/95 backdrop-blur-sm"
          variants={listItemVariants}
        >
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h2 className="text-2xl font-semibold text-gray-900">
                Visit Report
              </h2>
              <p className="text-sm text-gray-500">
                Created on {formatDate(report.created_at)}
              </p>
            </div>
            <button
              onClick={onClose}
              className="rounded-full p-2 hover:bg-gray-100 transition-colors text-gray-500 hover:text-gray-700"
            >
              <LuX className="w-5 h-5" />
            </button>
          </div>
        </motion.div>

        {/* Content */}
        <motion.div
          className="p-6 space-y-8 overflow-y-auto max-h-[calc(90vh-200px)]"
          variants={listItemVariants}
        >
          {/* Customer Info Section */}
          <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-6 space-y-4">
            <div className="flex flex-col items-center justify-between md:flex-row">
              <div className="flex items-center gap-3">
                <div className="p-3 bg-white rounded-lg shadow-sm">
                  <LuUser className="text-blue-600 w-6 h-6" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">
                    {report.customer}
                  </h3>
                  {/* <p className="text-sm text-gray-600">{report.companyID}</p> */}
                </div>
              </div>
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ring-1 ring-inset ${categoryColor(
                  report.customerCategory
                )}`}
              >
                Category {report.customerCategory}
              </span>
            </div>

            <div className="flex items-center gap-2 text-gray-600 pt-2">
              <LuUserCheck className="text-blue-600" />
              <span>
                Sales Representative:{" "}
                <span className="font-medium">{report.salesRep}</span>
              </span>
            </div>
          </div>

          {/* Visit Details Grid */}
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 gap-6"
            variants={listItemVariants}
          >
            <div className="space-y-6">
              <motion.div
                className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                variants={listItemVariants}
              >
                <div className="p-2 bg-white rounded-md shadow-sm">
                  <LuCalendarDays className="text-blue-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">
                    Date of Visit
                  </p>
                  <p className="text-gray-900 font-medium">
                    {formatDate(report.dateOfVisit)}
                  </p>
                </div>
              </motion.div>

              <motion.div
                className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                variants={listItemVariants}
              >
                <div className="p-2 bg-white rounded-md shadow-sm">
                  <LuTarget className="text-blue-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">Objective</p>
                  <p className="text-gray-900 whitespace-pre-wrap break-words">
                    {report.objective}
                  </p>
                </div>
              </motion.div>
            </div>

            <div className="space-y-6">
              {/* <div className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                <div className="p-2 bg-white rounded-md shadow-sm">
                  <LuBuilding className="text-blue-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">
                    Company ID
                  </p>
                  <p className="text-gray-900 font-medium">
                    {report.companyID}
                  </p>
                </div>
              </div> */}

              <div className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                <div className="p-2 bg-white rounded-md shadow-sm">
                  <LuClock className="text-blue-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-600">
                    Created At
                  </p>
                  <p className="text-gray-900">
                    {formatDate(report.created_at)}
                  </p>
                </div>
              </div>

              {report.followUpPlan && (
                <motion.div
                  className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                  variants={listItemVariants}
                >
                  <div className="p-2 bg-white rounded-md shadow-sm">
                    <LuCalendarClock className="text-blue-600 w-5 h-5" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-600">
                      Follow-up Plan
                    </p>
                    <p className="text-gray-900 whitespace-pre-wrap break-words">
                      {report.followUpPlan}
                    </p>
                  </div>
                </motion.div>
              )}
            </div>
          </motion.div>

          {/* Products & Content */}
          <motion.div className="space-y-6" variants={listItemVariants}>
            <div className="bg-white border border-gray-100 rounded-xl p-5 shadow-sm">
              <div className="flex items-center gap-2 mb-4">
                <LuPackage className="text-blue-600 w-5 h-5" />
                <h4 className="font-medium text-gray-900">
                  Products Discussed
                </h4>
              </div>
              <div className="flex flex-wrap gap-2">
                {products.map((product: string, index: number) => (
                  <span
                    key={index}
                    className="bg-blue-50 text-blue-700 px-3 py-1.5 rounded-full text-sm font-medium ring-1 ring-inset ring-blue-700/10 flex items-center gap-1"
                  >
                    {product}
                    <LuChevronRight className="w-4 h-4 text-blue-400" />
                  </span>
                ))}
              </div>
            </div>

            <motion.div
              className="bg-white border border-gray-100 rounded-xl p-5 shadow-sm"
              variants={listItemVariants}
            >
              <div className="flex items-center gap-2 mb-4">
                <LuClipboard className="text-blue-600 w-5 h-5" />
                <h4 className="font-medium text-gray-900">Content Covered</h4>
              </div>
              <div className="flex flex-wrap gap-2">
                {contents.map((content: string, index: number) => (
                  <span
                    key={index}
                    className="bg-gray-50 text-gray-700 px-3 py-1.5 rounded-full text-sm font-medium ring-1 ring-inset ring-gray-700/10"
                  >
                    {content}
                  </span>
                ))}
              </div>
            </motion.div>
          </motion.div>

          {/* Comment Section */}
          <motion.div
            className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl p-6 space-y-3"
            variants={listItemVariants}
          >
            {loading ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <LuMessageSquare className="text-blue-600 w-5 h-5" />
                  <h4 className="font-medium text-gray-900">Comments</h4>
                </div>
                <div className="flex items-center">
                  <svg
                    role="status"
                    className="w-5 h-5 text-blue-600 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50 100C77.614 100 100 77.614 100 50C100 22.386 77.614 0 50 0C22.386 0 0 22.386 0 50C0 77.614 22.386 100 50 100Z"
                      fill="#e5e7eb"
                    />
                    <path
                      d="M93.9716 50C93.9716 75.5097 75.5097 93.9716 50 93.9716C24.4903 93.9716 6.02844 75.5097 6.02844 50C6.02844 ..."
                      stroke="currentColor"
                      strokeWidth="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <LuMessageSquare className="text-blue-600 w-5 h-5" />
                  <h4 className="font-medium text-gray-900">Comments</h4>
                </div>
                {/* {!isEditingComment ? (
                  <button
                    onClick={handleEditComment}
                    className="p-2 hover:bg-gray-200 rounded-full transition-colors"
                  >
                    <LuClipboardEdit className="w-5 h-5 text-blue-600" />
                  </button>
                ) : (
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setIsEditingComment(false)}
                      className="flex items-center text-xl md:text-2xl text-blue-600 transition-all"
                    >
                      x
                    </button>
                    <button
                      onClick={handleSaveComment}
                      className="flex items-center gap-2 px-3 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      <LuSave className="w-4 h-4" />
                      Save
                    </button>
                  </div>
                )} */}
              </div>
            )}
            <div className="p-4 max-h-48 overflow-y-auto">
              {isEditingComment ? (
                <textarea
                  value={editedComment}
                  onChange={(e) => setEditedComment(e.target.value)}
                  className="w-full h-32 p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                />
              ) : (
                <div className="whitespace-pre-wrap break-words text-gray-700">
                  {report.comment}
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>

        {/* Footer */}
        <motion.div
          className="border-t border-gray-100 p-6 bg-gray-50"
          variants={listItemVariants}
        >
          {loading && (
            <svg
              role="status"
              className="inline w-5 h-5 mr-2 text-blue-600 animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50 100C77.614 100 100 77.614 100 50C100 22.386 77.614 0 50 0C22.386 0 0 22.386 0 50C0 77.614 22.386 100 50 100Z"
                fill="#e5e7eb"
              />
              <path
                d="M93.9716 50C93.9716 75.5097 75.5097 93.9716 50 93.9716C24.4903 93.9716 6.02844 75.5097 6.02844 50C6.02844 ..."
                stroke="currentColor"
                strokeWidth="10"
                strokeLinecap="round"
              />
            </svg>
          )}

          <button
            onClick={onClose}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2.5 px-4 rounded-lg transition-colors shadow-sm hover:shadow flex items-center justify-center gap-2"
          >
            Close Report
            <LuX className="w-4 h-4" />
          </button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
