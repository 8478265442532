import React, { useState, useRef, useEffect } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { motion } from "framer-motion";
import { cardContainerVariants } from "../../../../../animations/Animations";
// import UpdateContents from "./updateContents/UpdateContents";
import accessURL from "../../../../../components/utils/access";

const getFileFormat = (fileType: string): string => {
  if (fileType.includes("pdf")) return "PDF";
  if (fileType.includes("word")) return "DOCX";
  if (fileType.includes("sheet") || fileType.includes("excel")) return "XLSX";
  if (fileType.includes("presentation") || fileType.includes("powerpoint"))
    return "PPTX";
  return "Unknown";
};

type Content = {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
};

interface ContentItemProps {
  content: Content & { openPreview: (filePath: string) => void };
  index: any;
}

const ContentItem: React.FC<ContentItemProps> = ({ content, index }) => {
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        moreOptionsRef.current &&
        !moreOptionsRef.current.contains(event.target as Node)
      ) {
        setShowDeleteOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMoreClick = () => {
    setShowDeleteOption(!showDeleteOption);
  };

  const handleDeleteClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteOption(false);
    const response = await accessURL.post(`/contents/delete?id=${content.id}`);

    if (
      response.data &&
      response.data.status === "success" &&
      response.data.code === 200
    ) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <>
      <motion.div
        className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm"
        variants={cardContainerVariants}
        initial="hidden"
        animate="visible"
        custom={index}
      >
        <div className="flex justify-between items-start mb-2 py-4">
          <h3 className="text-lg font-semibold capitalize md:text-xl">
            {content.name}
          </h3>
          <div className="relative" ref={moreOptionsRef}>
            <button
              className="p-1 rounded-full hover:bg-gray-100"
              onClick={handleMoreClick}
            >
              <FiMoreVertical className="h-4 w-4 text-gray-500" />
            </button>
            {showDeleteOption && (
              <button
                className="absolute right-0 mt-1 bg-white border border-gray-200 rounded py-1 px-2 text-sm text-red-500 hover:bg-red-50"
                onClick={handleDeleteClick}
              >
                Delete
              </button>
            )}
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-3">{content.description}</p>
        <div className="flex justify-between items-center mb-4">
          <div>
            <p className="text-xs text-gray-500">Purpose</p>
            <p className="text-sm">{content.purpose}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Format</p>
            <p className="text-sm text-red-500">
              {getFileFormat(content.file_type || "")}
            </p>
          </div>
        </div>
        <div className="flex space-x-2 py-2">
          <button
            className="flex-1 bg-white text-blue-600 border border-blue-600 py-1 px-4 rounded"
            onClick={() => content.openPreview(content.file_path!)}
          >
            View
          </button>
          <button className="flex-1 bg-blue-600 text-white py-2 px-4 rounded w-full">
            Send
          </button>
          {/* <UpdateContents Content={content} /> */}
        </div>
      </motion.div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg z-50">
          Deleted successfully
        </div>
      )}
      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
            <p className="mb-4">
              This action is irreversible. Do you understand?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentItem;
