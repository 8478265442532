import React from "react";
import { FaEnvelope, FaUser } from "react-icons/fa";

const ManagerPersonalInfo: React.FC<{
  formData: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ formData, errors, handleChange }) => {
  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <FaUser className="text-lg" />
          <input
            type="text"
            name="fullName"
            placeholder="Manager Full Name"
            value={formData.fullName}
            onChange={handleChange}
            className="w-full p-2 bg-gray-100 rounded-md focus:outline-none"
          />
        </div>
        {errors.fullName && (
          <p className="text-red-500 text-sm">{errors.fullName}</p>
        )}
      </div>

      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <FaEnvelope className="text-lg" />
          <input
            type="email"
            name="email"
            placeholder="Manager Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 bg-gray-100 rounded-md focus:outline-none"
          />
        </div>
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>
    </>
  );
};

export default ManagerPersonalInfo;
