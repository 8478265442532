import { Variants } from "framer-motion";

export const slideFromRight: Variants = {
  hidden: {
    opacity: 0,
    x: -140,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "string",
      stiffness: 50,
      damping: 20,
      duration: 1.5,
    },
  },
};

export const listItemVariants: Variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const containerVariants: Variants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export const slideFromTop: Variants = {
  hidden: {
    opacity: 0,
    y: -140,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 20,
      duration: 1.5,
    },
  },
};

export const fadeInUp: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 20,
      duration: 1,
    },
  },
};

export const balloonAnimation: Variants = {
  hidden: { opacity: 0, scale: 0.5, y: 100 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
      duration: 0.8,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    y: 100,
    transition: {
      duration: 0.5,
    },
  },
};

export const snakyAnimation: Variants = {
  hidden: { y: 50, opacity: 0 },
  visible: (i: number) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.2,
      type: "spring",
      stiffness: 100,
      damping: 10,
    },
  }),
};

export const fadeIn: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export const cardVariants: Variants = {
  hidden: { opacity: 0, y: 50 },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.2,
      duration: 0.6,
      ease: "easeInOut",
    },
  }),
};

export const explosionAnimation: Variants = {
  initial: { opacity: 0, scale: 0.5 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

export const modalAnimation: Variants = {
  hidden: {
    opacity: 0,
    rotateX: 90,
    scaleY: 0.8,
    transition: {
      duration: 0.5,
      ease: [0.45, 0, 0.55, 1],
    },
  },
  visible: {
    opacity: 1,
    rotateX: 0,
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: [0.45, 0, 0.55, 1],
    },
  },
  exit: {
    opacity: 0,
    rotateX: 90,
    scaleY: 0.8,
    transition: {
      duration: 0.5,
      ease: [0.45, 0, 0.55, 1],
    },
  },
};

export const modalVariants: Variants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
};

export const overlayVariants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const cardContainerVariants: Variants = {
  hidden: { opacity: 0, y: 50 },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.2,
      duration: 0.5,
      type: "spring",
      stiffness: 120,
    },
  }),
};
