import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PersonnelContent from "../personnelContent/PersonnelContent";

type TabType = "manager" | "sales_rep";

const PersonnelTabs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = (searchParams.get("role") as TabType) || "manager";

  useEffect(() => {
    if (!searchParams.get("role")) {
      setSearchParams({ role: "manager" });
    }
  }, [searchParams, setSearchParams]);

  const handleTabChange = (tab: TabType) => {
    setSearchParams({ role: tab });
  };

  return (
    <section className="flex flex-col items-center space-y-5 md:items-start md:space-y-10">
      {/* Tab Buttons */}
      <div className="flex space-x-4 my-8">
        <button
          className={`px-6 py-2 rounded-md ${
            activeTab === "manager"
              ? "bg-blue-300 text-black font-bold"
              : "bg-gray-200"
          }`}
          onClick={() => handleTabChange("manager")}
        >
          Manager
        </button>
        <button
          className={`px-6 py-2 rounded-md ${
            activeTab === "sales_rep"
              ? "bg-blue-300 text-black font-bold"
              : "bg-gray-200"
          }`}
          onClick={() => handleTabChange("sales_rep")}
        >
          Sales Rep
        </button>
      </div>

      {/* Tab Content */}
      <PersonnelContent activeTab={activeTab} />
    </section>
  );
};

export default PersonnelTabs;
