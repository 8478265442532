import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accessURL from "../../components/utils/access";
import HeaderText from "../components/headerText/HeaderText";
import DescReport from "../components/reports/DescReport";
import Sort from "../components/personnel/sort/Sort";
import { SalesRepData } from "../../salesRep/components/schedule/types/types";
import LoadingSpinner from "../components/reports/LoadingSpinner";
import SalesRepCard from "../components/reports/SalesRepCard";
import EmptyState from "../components/reports/EmptyState";
import Pagination from "../components/reports/Pagination";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

const Reports: React.FC = () => {
  const [salesReps, setSalesReps] = useState<SalesRepData[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(10);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";

  useEffect(() => {
    fetchSalesReps();
  }, [currentPage, searchTerm]);

  const fetchSalesReps = async () => {
    const isSearch = searchTerm !== "";
    try {
      if (isSearch) setSearchLoading(true);
      const response = await accessURL.get<{
        data: { salesRep: SalesRepData[]; pagination: PaginationInfo };
      }>(
        `salesRep?page=${currentPage}&per_page=${perPage}${
          searchTerm ? `&name=${searchTerm}` : ""
        }`
      );
      setSalesReps(response.data.data.salesRep);
      setTotalPages(response.data.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching sales reps:", error);
    } finally {
      setLoading(false);
      if (isSearch) setSearchLoading(false);
    }
  };

  const handleViewReport = (name: string, id: number) => {
    navigate(`/admin/${email}/report/${id}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  if (loading) return <LoadingSpinner />;

  return (
    <section className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text="Reports" />
      </section>
      <DescReport />
      <div className="flex flex-col h-[calc(100vh-100px)] mx-4 my-10">
        <div className="flex justify-end py-10">
          <Sort onSearch={handleSearch} />
        </div>

        <div className="flex-grow bg-gray-50 rounded-lg shadow-lg p-6 scroll-bar-custom overflow-auto">
          {searchLoading ? (
            <LoadingSpinner />
          ) : salesReps.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {salesReps.map((rep) => (
                <SalesRepCard
                  key={rep.ID}
                  rep={rep}
                  onViewReport={handleViewReport}
                />
              ))}
            </div>
          )}
        </div>

        {totalPages > 1 && !searchLoading && salesReps.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </section>
  );
};

export default Reports;
