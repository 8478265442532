import React, { useState, useRef, ChangeEvent, DragEvent } from "react";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";

interface FileInputProps {
  onChange: (file: File | null) => void;
  accept?: string;
  maxSize?: number;
  currentFileName?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  onChange,
  accept = ".pdf,.doc,.ppt,.docx,.pptx,.xls,.xlsx",
  maxSize = 10 * 1024 * 1024,
  currentFileName = "",
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const allowedTypes = accept
    .split(",")
    .map((type) => type.trim().replace(".", ""));

  const handleFile = (selectedFile: File) => {
    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
    if (!fileExtension || !allowedTypes.includes(fileExtension)) {
      toast.error(
        `File type not allowed. Allowed types are: ${allowedTypes.join(", ")}`
      );
      return;
    }
    if (selectedFile.size > maxSize) {
      toast.error(`File size should not exceed ${maxSize / (1024 * 1024)}MB`);
      return;
    }
    setFile(selectedFile);
    onChange(selectedFile);
    simulateUpload();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const removeFile = () => {
    setFile(null);
    onChange(null);
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const simulateUpload = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 200);
  };

  return (
    <div
      className={`relative border-2 ${
        isDragging
          ? "border-blue-500"
          : file || currentFileName // Check for current file name
          ? "border-green-500"
          : "border-dashed border-gray-300"
      } rounded-lg p-6 transition-all duration-300 ease-in-out`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        backgroundImage: `linear-gradient(90deg, #4ade80 ${uploadProgress}%, transparent ${uploadProgress}%)`,
        backgroundSize: "100% 2px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left bottom",
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept={accept}
        className="hidden"
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        className="flex flex-col items-center justify-center cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-12 text-gray-400 mb-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <span className="text-sm text-blue-500 font-medium">Upload a file</span>
        <span className="text-xs text-gray-500 mt-2">or drag and drop</span>
        <span className="text-xs text-gray-500 mt-1">
          PDF, Doc, PPT up to {maxSize / (1024 * 1024)}MB
        </span>
      </label>
      {(file || currentFileName) && (
        <div className="mt-4 flex items-center justify-between bg-gray-100 p-2 rounded">
          <span className="text-sm truncate">
            {file?.name || currentFileName}
          </span>
          <button
            onClick={removeFile}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
};

export default FileInput;
