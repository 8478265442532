import React, { FC } from "react";

interface CardData {
  title: string;
  value: string;
  percentage?: string; // Optional percentage field
}

const cardData: CardData[] = [
  {
    title: "Total Planned Schedule",
    value: "+5278",
    percentage: "+19% from last month",
  },
  {
    title: "Total Achieved Schedule",
    value: "+923",
    percentage: "+28% from last week",
  },
  {
    title: "total sales",
    value: "+937",
    percentage: "+54% from last week",
  },
  {
    title: "total active manager",
    value: "473",
  },
  {
    title: "total active reps",
    value: "3234",
  },
];

const Cards: FC = () => {
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 ">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="border border-gray-300 rounded-lg shadow-sm p-4 flex flex-col h-full"
        >
          <h3 className="text-lg font-bold text-gray-800  flex items-center capitalize">
            {card.title}
          </h3>
          <div className="flex-grow flex flex-col justify-end">
            <p className="text-2xl font-bold text-black py-2">
              {card.value}
            </p>
            {card.percentage ? (
              <p className="text-sm text-gray-500">{card.percentage}</p>
            ) : (
              <p className="text-sm text-transparent">&#8203;</p>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Cards;
