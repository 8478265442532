import React from "react";
import { motion } from "framer-motion";
import PricingCard from "./PricingCard";
import { cardVariants } from "../../../../animations/Animations";

const PricingSection: React.FC = () => {
  return (
    <section className="bg-white py-10 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              title: "Free Trial",
              description:
                "Get a 7-day free trial on our platform and explore CRM in healthcare.",
              features: [
                "Access to our scheduling system",
                "Generate 5 reports",
              ],
              buttonText: "Get started",
            },
            {
              title: "Bronze Plan",
              description:
                "Max of 10 users. Able to schedule customer visits, report, and view dashboards.",
              features: [
                "Access to our scheduling system",
                "Access to our reporting system with instant notification",
                "Generate up to 50 reports",
                "Maximum of 10 managers",
                "Maximum of 10 sales reps",
              ],
              monthlyPrice: "N10,000",
              annualPrice: "N102,000",
              discount: "15%",
              buttonText: "Get started",
            },
            {
              title: "Silver Plan",
              description:
                "Max of 25 users. Schedule visits, track content, sales, and access dashboards.",
              features: [
                "Access to our scheduling system",
                "Access to our reporting system with instant notification",
                "Generate up to 100 reports",
                "Maximum of 15 managers",
                "Maximum of 25 sales reps",
                "Upload and track content and sales achievements",
              ],
              monthlyPrice: "N15,000",
              annualPrice: "N153,000",
              discount: "15%",
              buttonText: "Get started",
            },
            {
              title: "Gold Plan",
              description:
                "Unlimited users. Includes scheduling, reporting, email triggers, activity tracking, and more.",
              features: [
                "Unlimited scheduling",
                "Unlimited reports",
                "Track sales achievements and activities",
                "Unlimited managers and sales reps",
              ],
              monthlyPrice: "N25,000",
              annualPrice: "N255,000",
              discount: "15%",
              buttonText: "Get started",
            },
          ].map((plan, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={cardVariants}
            >
              <PricingCard
                title={plan.title}
                description={plan.description}
                features={plan.features}
                buttonText={plan.buttonText}
                monthlyPrice={plan.monthlyPrice}
                annualPrice={plan.annualPrice}
                discount={plan.discount}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
