import React from "react";
import Header from "../../../header/Header";
import InfoSection from "./InfoSection";

const Info: React.FC = () => {
  return (
    <div>
      <Header />
      <InfoSection />
    </div>
  );
};

export default Info;
