import React, { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../../assets/Logo.svg";
import { Outlet, useNavigate } from "react-router-dom";
import Navigation from "../components/navigation/Navigation";
import Large from "../components/headers/Large";
import MobileHeader from "../components/headers/MobileHeader";
import MobileNavigation from "../components/navigation/MobileNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../../store";


const ManagerLayout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);

  const token = useSelector((state: RootState) => state.user.user?.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const toggleSidebar = (): void => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMobileNav = (): void => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleMobileLinkClick = (): void => {
    setIsMobileNavOpen(false);
  };

  return (
    <main className="flex">
      {/* Sidebar (for large screens) */}
      <section
        className={`hidden lg:block ${
          isSidebarOpen ? "w-[15%]" : "w-16"
        } text-white transition-all duration-300 ease-in-out flex-shrink-0 h-full shadow-md border-r border-gray-400 fixed top-0 left-0`}
      >
        <div
          className={`px-4 flex items-center justify-between py-10 ${
            isSidebarOpen ? "border-b border-gray-400" : ""
          }`}
        >
          <button
            className="text-white text-2xl focus:outline-none"
            onClick={toggleSidebar}
          >
            <RxHamburgerMenu className="text-gray-800 text-xl lg:text-2xl md:text-4xl" />
          </button>

          {isSidebarOpen && (
            <img
              src={Logo}
              alt="syncai logo"
              className="ml-4 h-8 w-auto lg:ml-4 mt-2 lg:mt-0"
            />
          )}
        </div>

        <section className="h-full">
          <Navigation isSidebarOpen={isSidebarOpen} />
        </section>
      </section>

      {/* Header (for mobile with hamburger icon) */}
      <MobileHeader toggleMobileNav={toggleMobileNav} />

      {/* Mobile Navigation */}
      <MobileNavigation
        isMobileNavOpen={isMobileNavOpen}
        handleMobileLinkClick={handleMobileLinkClick}
      />

      {/* Content */}
      <section
        className={`flex-1 transition-all duration-300 ease-in-out lg:ml-[15%] w-[85%] min-h-screen`}
      >
        <header className="bg-white fixed top-0 right-0 py-8 border-b border-gray-400 shadow-sm lg:block hidden px-8 w-[85%] z-10">
          <Large />
        </header>
        <div className="pt-28 px-6">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default ManagerLayout;
