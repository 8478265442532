import React from "react";
import PricingHeader from "./PricingHeader";
import PricingSection from "./card/PricingSection";

const PricingComp: React.FC = () => {
  return (
    <section>
      <PricingHeader />
      <PricingSection/>
    </section>
  );
};

export default PricingComp;
