import React from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";

const Large: React.FC = () => {
  return (
    <section className="">
      <div className="flex items-center justify-between px-6">
        <div className="flex items-center border border-gray-300 rounded-md px-6 py-2">
          <input
            type="text"
            placeholder="Search"
            className="outline-none flex-grow"
          />
          <FaSearch className="text-gray-400 text-xl mr-2" />
        </div>

        <div className="flex items-center space-x-8 ml-4">
          <span className="font-bold">PX238MN</span>

          <span className="relative bg-gray-300 rounded-full p-2">
            <IoMdNotifications className="text-gray-400 w-8 h-8" />
          </span>

          <div className="flex items-center space-x-2">
            <img
              src="https://via.placeholder.com/40"
              alt="profile"
              className="rounded-full h-10 w-10"
            />
            <div>
              <p className="font-bold capitalize">dayo ogunpitan</p>
              <p className="text-sm text-gray-500">Admin</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Large;
