import React from "react";
import HeaderText from "../components/headerText/HeaderText";
import Desc from "../components/salesrep/Desc";
import Reports from "../components/salesrep/reports/Reports";

const SalesReps: React.FC = () => {
  return (
    <section className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text="Sales Represantative" />
      </section>
      <Desc />
      <Reports />
    </section>
  );
};

export default SalesReps;
