import React, { useState } from "react";
import { CgSearch } from "react-icons/cg";

interface SearchProps {
  onSearch: (searchTerm: string) => void;
}

const Sort: React.FC<SearchProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="flex items-center space-x-2 mt-4 bg-white rounded-md shadow-sm lg:mt-0">
      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="px-4 py-2 w-64 rounded-l-md focus:outline-none border border-blue-500"
      />
      <button
        onClick={handleSearch}
        className="bg-blue-500 text-white p-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700"
      >
        <CgSearch className="w-6 h-6" />
      </button>
    </div>
  );
};

export default Sort;
