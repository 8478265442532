import React from "react";
import { NavLink } from "react-router-dom";
import { RxPerson, RxExit } from "react-icons/rx";
import { MdDashboard } from "react-icons/md";

interface NavigationProps {
  isSidebarOpen: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ isSidebarOpen }) => {
  const baseLinkStyle = `flex items-center justify-start w-full p-2 text-gray-800 transition-all duration-300 ease-in-out 
    ${
      isSidebarOpen
        ? "hover:bg-blue-200 hover:border-l-[10px] hover:border-blue-600"
        : ""
    }`;
  const activeLinkStyle = "bg-blue-900 text-white border-l-4 border-blue-600";
  const iconStyle = "text-lg w-8 h-8";
  const closedIconStyle = "w-10 h-10 text-gray-700";

  const tooltipStyle = `relative group`;

  const tooltipTextStyle = `absolute left-full ml-2 px-2 py-1 bg-gray-600 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300`;

  return (
    <aside className="h-full">
      <div className="flex flex-col space-y-72 h-full py-10">
        {/* Top Links */}
        <ul className="space-y-4">
          <li className="font-bold text-lg md:text-xl">
            <NavLink
              to="/administrator/dashboard"
              className={({ isActive }) =>
                `${baseLinkStyle} ${isActive ? activeLinkStyle : ""} ${
                  !isSidebarOpen ? "justify-center" : ""
                }`
              }
            >
              <div
                className={`${tooltipStyle} flex items-center w-full space-x-4 px-4`}
              >
                {!isSidebarOpen ? (
                  <>
                    <MdDashboard className={closedIconStyle} />
                    <span className={tooltipTextStyle}>Dashboard</span>
                  </>
                ) : (
                  <>
                    <MdDashboard className={iconStyle} />
                    <span className="flex-grow text-left capitalize hidden md:inline">
                      dashboard
                    </span>
                  </>
                )}
              </div>
            </NavLink>
          </li>
          <li className="font-bold text-lg md:text-xl">
            <NavLink
              to="/administrator/admins"
              className={({ isActive }) =>
                `${baseLinkStyle} ${isActive ? activeLinkStyle : ""} ${
                  !isSidebarOpen ? "justify-center" : ""
                }`
              }
            >
              <div
                className={`${tooltipStyle} flex items-center w-full space-x-4 px-4`}
              >
                {!isSidebarOpen ? (
                  <>
                    <RxExit className={closedIconStyle} />
                    <span className={tooltipTextStyle}>Admins</span>
                  </>
                ) : (
                  <>
                    <RxExit className={iconStyle} />
                    <span className="flex-grow text-left capitalize hidden md:inline">
                      admins
                    </span>
                  </>
                )}
              </div>
            </NavLink>
          </li>
        </ul>

        {/* Bottom Links */}
        <ul className="space-y-4 mt-auto">
          <li className="font-bold text-lg md:text-xl">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                `${baseLinkStyle} ${isActive ? activeLinkStyle : ""} ${
                  !isSidebarOpen ? "justify-center" : ""
                }`
              }
            >
              <div
                className={`${tooltipStyle} flex items-center w-full space-x-4 px-4`}
              >
                {!isSidebarOpen ? (
                  <>
                    <RxPerson className={closedIconStyle} />
                    <span className={tooltipTextStyle}>Profile</span>
                  </>
                ) : (
                  <>
                    <RxPerson className={iconStyle} />
                    <span className="flex-grow text-left hidden md:inline">
                      Profile
                    </span>
                  </>
                )}
              </div>
            </NavLink>
          </li>
          <li className="font-bold text-lg md:text-xl">
            <NavLink
              to="/logout"
              className={({ isActive }) =>
                `${baseLinkStyle} ${isActive ? activeLinkStyle : ""} ${
                  !isSidebarOpen ? "justify-center" : ""
                }`
              }
            >
              <div
                className={`${tooltipStyle} flex items-center w-full space-x-4 px-4`}
              >
                {!isSidebarOpen ? (
                  <>
                    <RxExit className={closedIconStyle} />
                    <span className={tooltipTextStyle}>Logout</span>
                  </>
                ) : (
                  <>
                    <RxExit className={iconStyle} />
                    <span className="flex-grow text-left hidden md:inline">
                      Logout
                    </span>
                  </>
                )}
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Navigation;
