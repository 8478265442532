import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  IoClose,
  IoTimeOutline,
  IoPersonOutline,
  IoBusinessOutline,
  IoCalendarOutline,
  IoCartOutline,
} from "react-icons/io5";
import { format } from "date-fns";
import Cancel from "./Cancel";
import { Schedule } from "../../types/types";

interface ScheduleDetailsModalProps {
  schedule: Schedule | null;
  isOpen: boolean;
  onClose: () => void;
}

const formatTimeString = (timeString: string): string => {
  return format(new Date(`1970-01-01T${timeString}`), "hh:mm a");
};

const ScheduleDetailsModal: React.FC<ScheduleDetailsModalProps> = ({
  schedule,
  isOpen,
  onClose,
}) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen, onClose]);

  const parseProducts = (productsString: string): string[] => {
    try {
      return JSON.parse(productsString);
    } catch {
      return [];
    }
  };

  return (
    <AnimatePresence>
      {isOpen && schedule && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50"
            onClick={onClose}
          />

          {/* Modal */}
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <motion.div
                initial={{ scale: 0.95, opacity: 0, y: 20 }}
                animate={{ scale: 1, opacity: 1, y: 0 }}
                exit={{ scale: 0.95, opacity: 0, y: 20 }}
                transition={{
                  duration: 0.2,
                  ease: [0.16, 1, 0.3, 1],
                }}
                className="relative bg-white rounded-lg shadow-xl w-full max-w-md transform"
                onClick={(e) => e.stopPropagation()}
              >
                {/* Header with slide-down animation */}
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.1 }}
                  className="px-6 py-4 border-b border-gray-200"
                >
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-bold text-gray-900">
                      Schedule Details
                    </h2>
                    <button
                      onClick={onClose}
                      className="p-1 rounded-full hover:bg-gray-100 transition-colors"
                      type="button"
                      aria-label="Close modal"
                    >
                      <IoClose className="h-5 w-5 text-gray-500" />
                    </button>
                  </div>
                </motion.div>

                {/* Content with staggered fade-in animation */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="px-6 py-4 max-h-[calc(100vh-200px)] overflow-y-auto"
                >
                  <div className="space-y-6">
                    {/* Sales Rep Section */}
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.3 }}
                      className="flex items-start space-x-3"
                    >
                      <IoPersonOutline className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Sales Representative
                        </h3>
                        <p className="text-gray-600">{schedule.salesRep}</p>
                      </div>
                    </motion.div>

                    {/* Customer Section */}
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.4 }}
                      className="flex items-start space-x-3"
                    >
                      <IoBusinessOutline className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="font-medium text-gray-900">Customer</h3>
                        <p className="text-gray-600">{schedule.customer}</p>
                      </div>
                    </motion.div>

                    {/* Time Section */}
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.5 }}
                      className="flex items-start space-x-3"
                    >
                      <IoTimeOutline className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Schedule Time
                        </h3>
                        <p className="text-gray-600">
                          {formatTimeString(schedule.startTime)} -{" "}
                          {formatTimeString(schedule.endTime)}
                        </p>
                      </div>
                    </motion.div>

                    {/* Date Section */}
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.6 }}
                      className="flex items-start space-x-3"
                    >
                      <IoCalendarOutline className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Date of Visit
                        </h3>
                        <p className="text-gray-600">
                          {format(
                            new Date(schedule.dateOfVisit),
                            "MMMM dd, yyyy"
                          )}
                        </p>
                      </div>
                    </motion.div>

                    {/* Products Section */}
                    <motion.div
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.7 }}
                      className="flex items-start space-x-3"
                    >
                      <IoCartOutline className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
                      <div>
                        <h3 className="font-medium text-gray-900">Products</h3>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {parseProducts(schedule.products).map((product, index) => (
                            <span
                              key={index}
                              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                            >
                              {product}
                            </span>
                          ))}
                        </div>
                      </div>
                    </motion.div>

                    {/* Objective Section */}
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.8 }}
                      className="border-t pt-4"
                    >
                      <h3 className="font-medium text-gray-900 mb-2">
                        Objective
                      </h3>
                      <p className="text-gray-600 whitespace-pre-wrap">
                        {schedule.objective}
                      </p>
                    </motion.div>
                  </div>
                </motion.div>

                {/* Footer with slide-up animation */}
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.9 }}
                  className="px-6 py-4 border-t border-gray-200"
                >
                  <Cancel schedule={schedule} />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ScheduleDetailsModal;