import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  containerVariants,
  modalVariants,
} from "../../../../../../animations/Animations";

interface FollowUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (followUpPlan: string) => void;
  isSuccess: boolean;
}

const FollowupModal: React.FC<FollowUpModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isSuccess,
}) => {
  const [followUpPlan, setFollowUpPlan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (followUpPlan.trim() === "") {
      setError("Please enter a follow-up plan.");
      return;
    }

    setIsLoading(true);
    setError("");

    onSubmit(followUpPlan);
    setIsLoading(false);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50"
        initial="hidden"
        animate="visible"
        exit="hidden"
      />
      <motion.div
        className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative z-10"
        variants={modalVariants}
      >
        {isSuccess ? (
          <>
            <h2 className="text-2xl font-bold mb-4">
              Follow-Up Plan Saved Successfully!
            </h2>
            <p className="text-gray-600 mb-4">
              The follow-up plan has been saved.
            </p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">Add Follow-Up Plan</h2>
            <textarea
              className="w-full h-32 p-2 border rounded-md mb-4 outline-none"
              placeholder="Enter your follow-up plan..."
              value={followUpPlan}
              onChange={(e) => setFollowUpPlan(e.target.value)}
            ></textarea>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
                  isLoading || followUpPlan.trim() === ""
                    ? "cursor-not-allowed"
                    : ""
                }`}
                onClick={handleSubmit}
                disabled={isLoading || followUpPlan.trim() === ""}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default FollowupModal;