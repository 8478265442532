import { TableStyles } from "react-data-table-component";

export const customStyles: TableStyles = {
  table: {
    style: {
      backgroundColor: "#f8fafc",
      borderRadius: "0.5rem",
      overflow: "hidden",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#1e40af",
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase" as const,
    },
  },
  rows: {
    style: {
      minHeight: "60px",
    },
    stripedStyle: {
      backgroundColor: "#f1f5f9",
    },
  },
  cells: {
    style: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "#f8fafc",
      color: "#1e40af",
      fontWeight: "bold",
    },
    pageButtonsStyle: {
      borderRadius: "0.375rem",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "0 5px",
      cursor: "pointer",
      transition: "0.2s ease-out",
      color: "#1e40af",
      fill: "#1e40af",
      backgroundColor: "transparent",
      "&:disabled": {
        cursor: "unset",
        color: "#cbd5e1",
        fill: "#cbd5e1",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#dbeafe",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#bfdbfe",
      },
    },
  },
};
