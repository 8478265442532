import React from "react";
import HeaderText from "../components/headerText/HeaderText";
// import CreateTabs from "../components/personnel/createManSaleAssign/CreateTabs";
import PersonnelInfo from "../components/personnel/personnelInfo/PersonnelInfo";

const Personnel: React.FC = () => {
  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        {/* HeaderText component */}
        <HeaderText text="personnel" />
      </section>
      {/* <CreateTabs /> */}

      <section>
        <PersonnelInfo />
      </section>
    </div>
  );
};

export default Personnel;
