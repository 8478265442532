import React from "react";
import { FieldError, UseFormRegister } from "react-hook-form";

interface TextInputProps {
  label: string;
  id: string;
  type: string;
  placeholder: string;
  error?: FieldError;
  register: UseFormRegister<any>;
  validation: any;
}

const TextInput: React.FC<TextInputProps> = ({ label, id, type, placeholder, error, register, validation }) => (
  <div>
    <label className="font-semibold mb-1 block text-gray-700">{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      id={id}
      {...register(id, validation)}
      className={`w-full px-4 py-2 rounded-md border ${error ? "border-red-500" : "border-gray-300"} outline-none`}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error.message}</p>}
  </div>
);

export default TextInput;
