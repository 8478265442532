import Reports from "../pages/Reports";
import SalesRepLayout from "../layout/SalesRepLayout";
import Customer from "../pages/Customer";
import Dashboard from "../pages/Dashboard";
import Product from "../pages/Product";
import Schedule from "../pages/Schedule";
import SingleReports from "../../admin/pages/SingleReports";

const SalesRepRoutes = [
  {
    path: "/salesRep/:name",
    element: <SalesRepLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "customers",
        element: <Customer />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "schedule",
        element: <Schedule />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      
    ],
  },
];
export default SalesRepRoutes;
