import React, { useState, FormEvent } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import CustomerSelector from "./CustomerSelector";
import ProductSelector from "./ProductSelector";

import {
  ScheduleAPIData,
  ScheduleFormData,
  ScheduleModalProps,
} from "./types/types";
import TimeGrid from "./timegrid/TimeGrid";
import DateSelector from "./timegrid/DateSelector";

const CreateScheduleModal: React.FC<ScheduleModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<ScheduleFormData>({
    visitObjective: "",
    date: "",
    startTime: "",
    endTime: "",
    selectedCustomer: null,
    selectedProducts: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<
    Partial<Record<keyof ScheduleFormData, string>>
  >({});

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof ScheduleFormData, string>> = {};

    if (!formData.visitObjective)
      newErrors.visitObjective = "Visit objective is required";
    if (!formData.date) newErrors.date = "Date is required";
    if (!formData.startTime) newErrors.startTime = "Start time is required";
    if (!formData.endTime) newErrors.endTime = "End time is required";
    if (!formData.selectedCustomer)
      newErrors.selectedCustomer = "Customer selection is required";
    if (formData.selectedProducts.length === 0)
      newErrors.selectedProducts = "At least one product must be selected";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const scheduleData: ScheduleAPIData = {
        visitObjective: formData.visitObjective,
        date: formData.date,
        startTime: formData.startTime,
        endTime: formData.endTime,
        customerId: formData.selectedCustomer?.id,
        productIds: formData.selectedProducts.map((product) => product.id),
      };

      await onSubmit(scheduleData);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearError = (field: keyof ScheduleFormData) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-2xl relative z-60 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Create New Schedule</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Visit Objective */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Visit Objective
            </label>
            <input
              type="text"
              value={formData.visitObjective}
              onChange={(e) =>
                setFormData({ ...formData, visitObjective: e.target.value })
              }
              onFocus={() => clearError("visitObjective")}
              className="mt-1 block w-full px-4 py-2 border rounded-md"
              placeholder="Enter visit objective"
            />
            {errors.visitObjective && (
              <p className="text-red-500 text-sm mt-1">
                {errors.visitObjective}
              </p>
            )}
          </div>

          {/* Date and Products Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <DateSelector
              date={formData.date}
              onChange={(date) => setFormData({ ...formData, date })}
              error={errors.date}
              clearError={clearError}
            />

            <ProductSelector
              selectedProducts={formData.selectedProducts}
              onProductsChange={(products) => {
                setFormData({ ...formData, selectedProducts: products });
                clearError("selectedProducts");
              }}
              error={errors.selectedProducts}
            />
          </div>

          {/* Time Grid Component */}
          <TimeGrid
            startTime={formData.startTime}
            endTime={formData.endTime}
            onStartTimeChange={(time) =>
              setFormData({ ...formData, startTime: time })
            }
            onEndTimeChange={(time) =>
              setFormData({ ...formData, endTime: time })
            }
            errors={{
              startTime: errors.startTime,
              endTime: errors.endTime,
            }}
            clearError={clearError}
          />

          {/* Customer Selection */}
          <CustomerSelector
            selectedCustomer={formData.selectedCustomer}
            onCustomerSelect={(customer) => {
              setFormData({ ...formData, selectedCustomer: customer });
              clearError("selectedCustomer");
            }}
            error={errors.selectedCustomer}
          />

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isLoading || Object.keys(errors).length > 0}
              className={`px-6 py-2 rounded-md ${
                isLoading || Object.keys(errors).length > 0
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              }`}
            >
              {isLoading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                "Create Schedule"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateScheduleModal;
