import React, { useState } from "react";
import { Reports } from "../../schedule/types/types";
import { LuBook } from "react-icons/lu";
import { HiDotsVertical } from "react-icons/hi";
import Modal from "./Modal";
import accessURL from "../../../../components/utils/access";

interface ReportCardProps {
  report: Reports;
}

const ReportCard: React.FC<ReportCardProps> = ({ report }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const categoryColor = () => {
    switch (report.customerCategory) {
      case "A":
        return "text-green-700";
      case "B":
        return "text-yellow-700";
      case "C":
        return "text-red-700";
      default:
        return "text-gray-700";
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDelete = async () => {
    const response = await accessURL.post(`/reports/delete?id=${report.id}`);
    if (response.status === 200) {
      setDeleteSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setDeleteSuccess(false);
      closeDeleteConfirm();
    }
  };

  return (
    <div className="bg-white shadow-xl rounded-md p-4">
      {/* Top Section */}
      <div className="flex justify-between items-center mb-2 py-2">
        <LuBook
          className="text-xl cursor-pointer text-blue-600 md:text-4xl"
          onClick={openModal}
        />
        <div className="relative">
          <HiDotsVertical
            className="cursor-pointer text-xl text-blue-600 md:text-4xl"
            onClick={toggleOptions}
          />
          {showOptions && (
            <div className="absolute right-0 mt-2 bg-white border rounded shadow-lg">
              <button
                onClick={openDeleteConfirm}
                className="block px-4 py-2 text-left text-red-500 w-full"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
      <h3 className="text-xl capitalize font-bold text-black mb-2 truncate py-3">
        {report.customer}
      </h3>
      <div className="flex flex-col md:flex-row items-center justify-between">
        {report.followUpPlan && report.followUpPlan !== "" && (
          <div
            className="group relative max-w-[70%]"
            title="Click on view to see follow up plan"
          >
            <p className="text-purple-600 font-medium truncate">
              Follow up: {report.followUpPlan}
            </p>
          </div>
        )}
        <p className={`text-right text-xl md:text-2xl ${categoryColor()}`}>
          {report.customerCategory}
        </p>
      </div>

      <p className="text-gray-600 mb-2 truncate py-2">
        Details of Visit: {report.objective}
      </p>
      <button
        className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center transition duration-300 ease-in-out w-full"
        onClick={openModal}
      >
        View
      </button>

      {/* View Report Modal */}
      <Modal isOpen={modalOpen} onClose={closeModal} report={report} />

      {/* Delete Confirmation Modal */}
      {deleteConfirmOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center w-80">
            <h2 className="text-lg font-bold mb-4">Confirm Delete</h2>
            <p className="text-gray-700 mb-4">Are you sure you want to delete this report?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Yes, Delete
              </button>
              <button
                onClick={closeDeleteConfirm}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Success Modal */}
      {deleteSuccess && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 animate-fadeIn">
          <div className="bg-white p-6 rounded shadow-lg text-center w-80">
            <h2 className="text-lg font-bold mb-4 text-green-600">Deleted Successfully</h2>
            <p className="text-gray-700">The report has been deleted.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportCard;
