import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import accessURL from "../../../../../../components/utils/access";
import Sort from "../../../sort/Sort";
import Action from "./Action";
import { SalesRepData } from "../../../../../../salesRep/components/schedule/types/types";

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

const SalesRep: React.FC = () => {
  const [data, setData] = useState<SalesRepData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchSalesReps(currentPage, perPage, searchTerm);
  }, [currentPage, perPage, searchTerm]);

  const fetchSalesReps = async (
    page: number,
    pageSize: number,
    search: string
  ) => {
    setLoading(true);
    try {
      const response = await accessURL.get<{
        data: { salesRep: SalesRepData[]; pagination: PaginationInfo };
      }>(
        `salesRep?page=${page}&per_page=${pageSize}${
          search ? `&name=${search}` : ""
        }`
      );
      setData(response.data.data.salesRep);
      setTotalRows(response.data.data.pagination.total);
    } catch (error) {
      console.error("Error fetching sales reps:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns: TableColumn<SalesRepData>[] = [
    {
      name: "S/N",
      selector: (row: SalesRepData) => row.ID,
      format: (row: SalesRepData, index: number) =>
        (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row: SalesRepData) => row.name,
    },
    {
      name: "Email",
      selector: (row: SalesRepData) => row.email,
      width: "200px",
    },
    {
      name: "Assigned Manager",
      selector: (row: SalesRepData) => row.assignedManager || "N/A",
    },
    {
      name: "Actions",
      cell: (row: SalesRepData) => (
        <Action id={row.ID} name={row.name} assigned={row.assigned} />
      ),
      width: "300px",
    },
  ];

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col space-y-4">
      {data.length > 0 && (
        <div className="flex justify-end">
          <Sort onSearch={handleSearch} />
        </div>
      )}

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          striped
          highlightOnHover
          responsive
        />
      </div>
    </div>
  );
};

export default SalesRep;
