import React from 'react';
import { ScheduleFormData } from '../types/types';

interface DateSelectorProps {
  date: string;
  onChange: (date: string) => void;
  error?: string;
  clearError: (field: keyof ScheduleFormData) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  date,
  onChange,
  error,
  clearError,
}) => {
  
  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Date
      </label>
      <div className="relative">
        <input
          type="date"
          value={date}
          min={today} 
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => clearError("date")}
          className="mt-1 block w-full px-4 py-2 border rounded-md"
        />
      </div>
      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default DateSelector;