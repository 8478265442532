import React from "react";
import PersonnelTabs from "./personnelTabs/PersonnelTabs";

const PersonnelInfo: React.FC = () => {
  return (
    <section>
      <PersonnelTabs />
    </section>
  );
};

export default PersonnelInfo;
