import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { fadeInUp } from "../../animations/Animations";
import SignupModal from "../modals/SIgnupModal";
import { BaseURL } from "../../components/utils/url";
import TextInput from "./custom_components/TextInput";
import PasswordInput from "./custom_components/PasswordInput";
import TermsCheckbox from "./custom_components/TermsCheckBox";
import SubmitButton from "./custom_components/SubmitButton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { authUser } from "../../features/users/UsersSlice";

interface IFormInput {
  fullname?: string;
  email: string;
  companyName?: string;
  password: string;
  termsAccepted?: boolean;
}

const Auth: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInput>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const location = useLocation();
  const isSignUp = location.pathname === "/signup";

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsSubmitting(true);
    const payload = {
      password: data.password,
      email: data.email,
      name: data.companyName,
    };

    try {
      if (isSignUp) {
        const response = await BaseURL.post("/auth/signup", payload);

        if (response.status === 200) {
          setSignUpSuccess(true);
        } else {
          toast.warn("Organization already exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        const response = await BaseURL.post("/auth/login", data);
        if (response.status === 200) {
          dispatch(authUser(response?.data?.data));

          const { email, role } = response?.data?.data?.userInfo;

         

          if (role === "admin") {
            setTimeout(() => {
              navigate(`/admin/${email}`);
            }, 1000);
          } else if (role === "manager") {
            setTimeout(() => {
              navigate(`/manager/${email}`);
            }, 1000);
          } else if (role === "salesRep") {
            setTimeout(() => {
              navigate(`/salesRep/${email}`);
            }, 1000);
          }
        } else {
          toast.error("Invalid Credentials", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      toast.error("an error occured", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const passwordValue = watch("password", "");

  const validatePassword = (password: string) => {
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return hasLowercase && hasUppercase && hasNumber && hasSpecialChar;
  };

  const getPasswordBorderColor = () => {
    if (passwordValue) {
      return validatePassword(passwordValue)
        ? "border-green-500"
        : "border-yellow-500";
    } else if (errors.password) {
      return "border-red-500";
    }
    return "border-gray-300";
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-4"
      variants={fadeInUp}
      initial="hidden"
      animate="visible"
    >
      {isSignUp && (
        <>
          <TextInput
            label="Company Name"
            id="companyName"
            type="text"
            placeholder="Enter your company name"
            register={register}
            validation={{ required: "Company name is required" }}
            error={errors.companyName}
          />
        </>
      )}
      <TextInput
        label="Email"
        id="email"
        type="email"
        placeholder="Enter your email"
        register={register}
        validation={{
          required: "Email is required",
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Invalid email format",
          },
        }}
        error={errors.email}
      />
      <PasswordInput
        id="password"
        error={errors.password}
        register={register}
        validation={{
          required: "Password is required",
          validate: (value: any) =>
            validatePassword(value) ||
            "Password must include uppercase, lowercase, number, and special character",
        }}
        getPasswordBorderColor={getPasswordBorderColor}
      />
      {!isSignUp && (
        <div className="text-right">
          <Link
            to="/forgot-password"
            className="text-sm text-blue-600 hover:underline"
          >
            Forgot Password?
          </Link>
        </div>
      )}

      {isSignUp && (
        <TermsCheckbox
          error={errors.termsAccepted}
          register={register}
          setTerms={setTerms}
        />
      )}
      <SubmitButton
        isSubmitting={isSubmitting}
        isSignUp={isSignUp}
        terms={terms}
      />
      <SignupModal
        show={isSignUpSuccess}
        onClose={() => setSignUpSuccess(false)}
      />
    </motion.form>
  );
};

export default Auth;
