import React, { useState } from "react";
import { FaUpload } from "react-icons/fa";
import ContentUploadModal from "./ContentUploadModal";
import accessURL from "../../../../../../components/utils/access";
import { toast } from "react-toastify";

const AddContent: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (formData: FormData) => {
    setIsLoading(true);
    const response = await accessURL.post("/contents/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 201) {
      toast.success("Content created successfully!");

      setTimeout(() => {
        window.location.reload();
        setIsModalOpen(false);
      }, 2000);
      setIsLoading(false);
    } else if (response.status === 409) {
      toast.error("A Content with this name already exists.");
      setIsLoading(false);
    } else {
      toast.error("An error occurred. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-2 bg-blue-900 text-white px-4 py-2 rounded text-xl"
      >
        <FaUpload /> Upload Content
      </button>

      <div className="z-[1000000]">
        <ContentUploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onUpload={handleUpload}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AddContent;
