import React from "react";
import Products from "./products/Products";
import Contents from "./contents/Contents";

interface PersonnelConProps {
  activeTab: string;
}

const Display: React.FC<PersonnelConProps> = ({ activeTab }) => {
  return (
    <div className="w-full max-w-screen-4xl shadow-sm bg-white rounded-xl">
      {activeTab === "products" && (
        <div className="p-4 rounded-lg">
          <Products />
        </div>
      )}
      {activeTab === "contents" && (
        <div className="p-4 border rounded-lg">
          <Contents />
        </div>
      )}
    </div>
  );
};

export default Display;
