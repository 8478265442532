import React from "react";
import { motion } from "framer-motion";

interface SubmitButtonProps {
  isSubmitting: boolean;
  isSignUp: boolean;
  terms: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  isSignUp,
  terms,
}) => {
  const isDisabled = isSubmitting || (isSignUp && !terms);

  return (
    <motion.button
      type="submit"
      className={`w-full py-2 rounded-md transition-colors ${
        isDisabled
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-blue-500 hover:bg-blue-600 text-white"
      }`}
      disabled={isDisabled}
    >
      {isSubmitting ? "Submitting..." : isSignUp ? "Create Account" : "Login"}
    </motion.button>
  );
};

export default SubmitButton;