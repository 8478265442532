import React, { useState, FormEvent, useEffect } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import {
  modalVariants,
  overlayVariants,
} from "../../../../animations/Animations";

interface UpdateCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (formData: FormData) => void;
  isLoading: boolean;
  customerData: CustomerData;
}

interface CustomerData {
  id: number;
  name: string;
  email: string;
  phone: string;
  state: string;
  city: string;
  category: string;
}

const UpdateCustomerModal: React.FC<UpdateCustomerModalProps> = ({
  isOpen,
  onClose,
  onUpdate,
  isLoading,
  customerData,
}) => {
  const [name, setName] = useState<string>(customerData.name);
  const [email, setEmail] = useState<string>(customerData.email);
  const [phone, setPhone] = useState<string>(customerData.phone);
  const [state, setState] = useState<string>(customerData.state);
  const [city, setCity] = useState<string>(customerData.city);
  const [category, setCategory] = useState<string>(customerData.category);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  useEffect(() => {
    const formFields = [name, email, phone, state, city, category];
    const isAnyFieldEmpty = formFields.some((field) => field.trim() === "");
    setIsFormValid(!isAnyFieldEmpty);

    const isChanged =
      name !== customerData.name ||
      email !== customerData.email ||
      phone !== customerData.phone ||
      state !== customerData.state ||
      city !== customerData.city ||
      category !== customerData.category;
    setIsFormChanged(isChanged);
  }, [name, email, phone, state, city, category, customerData]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      toast.error("Please fill all required fields");
      return;
    }
    if (!isFormChanged) {
      toast.info("No changes detected");
      return;
    }
    const formData = new FormData();
    formData.append("id", customerData.id.toString());
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("category", category);
    onUpdate(formData);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={overlayVariants}
    >
      <motion.div
        className="bg-white p-6 rounded-lg w-full max-w-2xl relative z-60 max-h-[90vh] overflow-y-auto"
        variants={modalVariants}
      >
        <h2 className="text-3xl font-bold mb-4 py-3">Update Customer</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <input
                id="state"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              >
                <option value="">Select a category</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className={`px-6 py-3 font-semibold text-white rounded-md ${
                isFormValid && isFormChanged
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={!isFormValid || !isFormChanged || isLoading}
            >
              {isLoading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                "Update Customer"
              )}
            </button>
          </div>
        </form>

        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-red-500 text-4xl hover:text-red-700"
        >
          &times;
        </button>
      </motion.div>
    </motion.div>
  );
};

export default UpdateCustomerModal;
