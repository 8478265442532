import React from "react";

const Dashboard: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Under Construction
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          We're working hard to bring you new features. Stay tuned!
        </p>

        <p>personnel and product navigations are live</p>
      </div>
    </div>
  );
};

export default Dashboard;
