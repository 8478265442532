import React, { useState } from "react";
import ContentUpdateModal from "./ContentUpdateModal";
import accessURL from "../../../../../../components/utils/access";
import { toast } from "react-toastify";

type Content = {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
};

interface Props {
  Content: Content;
}

const UpdateContents: React.FC<Props> = ({ Content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (formData: FormData) => {
    setIsLoading(true);

    const response = await accessURL.post(
      `/contents/update?id=${Content.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      toast.success("Content updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }

    setIsLoading(false);
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex-1 bg-blue-600 text-white py-2 px-4 rounded w-full"
      >
        Update
      </button>

      <div className="z-[1000]">
        <ContentUpdateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onUpload={handleUpdate}
          isLoading={isLoading}
          contentData={Content}
        />
      </div>
    </>
  );
};

export default UpdateContents;
