import React, { useEffect, useState } from "react";
import { FaUser, FaClipboardList } from "react-icons/fa";
import accessURL from "../../../../../../components/utils/access";


interface DetProps {
  id: number;
}

const Det: React.FC<DetProps> = ({ id }) => {
  const [name, setName] = useState<string>("");
  const [totalReports, setTotalReports] = useState<number | null>(null);

  useEffect(() => {
    const fetchReports = async () => {
      const response = await accessURL.get(`reports/singleReport?id=${id}`);
      setName(response.data.data.salesRep);
      setTotalReports(response.data.data.pagination.totalRecords);
    };
    fetchReports();
  }, [id]);

  return (
    <div className="flex flex-col items-center justify-center w-full p-8 bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-lg">
      <div className="flex flex-col items-center space-y-4 md:flex-row md:items-center md:space-x-6 md:space-y-0">
        <FaUser className="text-5xl md:text-6xl" />
        <span className="text-3xl md:text-5xl font-bold">
          {name || "Loading..."}
        </span>
      </div>
      {totalReports !== null && (
        <div className="mt-4 text-xl md:text-2xl font-medium text-gray-600 flex items-center space-x-2">
          <FaClipboardList className="text-2xl md:text-3xl" />
          <span>
            Total Reports: <strong>{totalReports}</strong>
          </span>
        </div>
      )}
    </div>
  );
};

export default Det;
