import React, { useState, useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import ManagerPersonalInfo from "../components/managers/ManagerPersonalInfo";
import ManagerSecurityInfo from "../components/managers/ManagerSecurityInfo";
import accessURL from "../../components/utils/access";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { explosionAnimation } from "../../animations/Animations";
import { CgArrowLeft } from "react-icons/cg";

const CreateManager: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const userEmail = userInfo?.email || "";

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const isValid = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const payload = {
        name: formData.fullName,
        email: formData.email,
        password: formData.password,
      };

      const response = await accessURL.post("managers/create", payload);

      if (response.status === 201) {
        toast.success("Manager created successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate(`/admin/${userEmail}/personnel?role=manager`);
        }, 3000);
      } else {
        toast.warn("User already exists", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="p-4 max-w-4xl mx-auto bg-white text-black rounded-md shadow-lg my-20 md:p-6 lg:p-8 xl:p-10 "
        variants={explosionAnimation}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-blue-600 hover:text-blue-700 transition-all duration-300 mb-4"
        >
          <CgArrowLeft className="mr-2" /> Back
        </button>
        <h2 className="text-xl md:text-2xl font-bold mb-6 flex items-center justify-center">
          <FaUserPlus className="mr-2" /> Create Manager
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <ManagerPersonalInfo
            formData={formData}
            errors={errors}
            handleChange={handleChange}
          />
          <ManagerSecurityInfo
            formData={formData}
            errors={errors}
            handleChange={handleChange}
          />
          <button
            type="submit"
            className={`w-full bg-blue-600 text-white p-2 rounded-md transition-all duration-300 ${
              isFormValid && !isLoading
                ? "hover:bg-blue-700"
                : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? "Creating..." : "Create"}
          </button>
        </form>
      </motion.div>
    </AnimatePresence>
  );
};

export default CreateManager;
