import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";

const SalesRepSecurityInfo: React.FC<{
  formData: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ formData, errors, handleChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-2 relative">
        <FaLock className="text-lg" />
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="SalesRep  Password"
          value={formData.password}
          onChange={handleChange}
          className="w-full p-2 bg-gray-100 rounded-md focus:outline-none pr-10"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
        >
          {showPassword ? (
            <FaEyeSlash className="text-gray-500" />
          ) : (
            <FaEye className="text-gray-500" />
          )}
        </button>
      </div>
      {errors.password && (
        <p className="text-red-500 text-sm">{errors.password}</p>
      )}
    </div>
  );
};
export default SalesRepSecurityInfo;
