import React from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useNavigate, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { balloonAnimation } from "../../animations/Animations";

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const SignupModal: React.FC<ModalProps> = ({ show, onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate("/login");
    window.location.reload();
  };

  return (
    <AnimatePresence>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 z-60">
          <motion.div
            className="bg-white rounded-lg p-10 shadow-xl w-full max-w-xl lg:max-w-2xl lg:w-2/5 mx-4"
            variants={balloonAnimation}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="flex justify-end">
              <FaTimes
                className="text-gray-600 text-3xl cursor-pointer hover:text-gray-800 transition"
                onClick={handleClose}
              />
            </div>

            {/* Content */}
            <div className="flex items-center justify-start mb-4">
              <FaCheckCircle className="text-green-500 text-4xl mr-4" />
              <h2 className="text-3xl font-bold text-gray-800">
                Signup Successful
              </h2>
            </div>

            <p className="mt-4 text-lg text-gray-700">
              Your account has been created! Please check your email for your
              company ID. You can now create managers, assign sales
              representatives, and access the CRM dashboard functionalities.
            </p>

            {/* Subscription Info */}
            <p className="mt-6 text-lg text-gray-700">
              To enjoy premium features and enhanced CRM functionalities, please
              go through our subscription packages and choose the one that best
              suits your business needs.
            </p>
            <NavLink
              to="/pricing"
              className="text-blue-500 font-bold mt-4 inline-block hover:underline"
            >
              View Subscription Plans
            </NavLink>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default SignupModal;
