import React, { useState, useEffect } from "react";
import accessURL from "../../../../../components/utils/access";
import { format } from "date-fns";
import "./scrollbar.css";
import ScheduleDetailsModal from "./ScheduleDetailsModal";
import { Schedule } from "../../types/types";

interface ApiResponse {
  schedules: Schedule[];
  totalPages: number;
}

interface SchedulesProps {
  selectedDate: Date | null;
}

const formatTimeString = (timeString: string): string => {
  return format(new Date(`1970-01-01T${timeString}`), "hh:mm a");
};

const timeColors = [
  { bg: "bg-purple-100", text: "text-purple-800" },
  { bg: "bg-blue-100", text: "text-blue-800" },
  { bg: "bg-green-100", text: "text-green-800" },
  { bg: "bg-rose-100", text: "text-rose-800" },
  { bg: "bg-amber-100", text: "text-amber-800" },
  { bg: "bg-teal-100", text: "text-teal-800" },
];

const Schedules: React.FC<SchedulesProps> = ({ selectedDate }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const limit = 10;
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const formattedDate = selectedDate
    ? format(selectedDate, "MM/dd/yyyy")
    : null;

  useEffect(() => {
    const fetchSchedules = async () => {
      if (!formattedDate) {
        setData(null);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await accessURL.get<ApiResponse>(
          `/schedules?date=${formattedDate}&page=${page}&limit=${limit}`
        );
        setData(response?.data);
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "An error occurred while fetching schedules"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchedules();
  }, [formattedDate, page]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-gray-600">Loading schedules...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-red-600">Failed to load schedules: {error}</div>
      </div>
    );
  }

  if (!data) return null;

  const handleScheduleClick = (schedule: Schedule): void => {
    setSelectedSchedule(schedule);
    setIsModalOpen(true);
  };

  return (
    <div className="p-4 flex flex-col h-[calc(100vh-200px)] ">
      <h2 className="text-lg font-bold mb-4">Upcoming Schedule</h2>
      <div className="bg-white rounded-lg shadow flex-1 flex flex-col min-h-0">
        {data.schedules.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No schedules for the selected date
          </div>
        ) : (
          <div className="overflow-y-auto flex-1 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent  custom-scrollbar hover:scrollbar-thumb-gray-400">
            <ul className="divide-y divide-gray-200">
              {data.schedules.map((schedule: Schedule, index: number) => {
                const colorStyle = timeColors[index % timeColors.length];

                return (
                  <li
                    key={schedule.id}
                    className="p-4 hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
                    onClick={() => handleScheduleClick(schedule)}
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                      <div className="flex-1 min-w-0">
                        <h3 className="font-bold text-gray-900 truncate">
                          {schedule.customer}
                        </h3>
                        <p className="text-sm text-gray-600 truncate">
                          {schedule.objective.length > 50
                            ? `${schedule.objective.substring(0, 50)}...`
                            : schedule.objective}
                        </p>
                        <p className="text-xs text-gray-500 mt-1">
                          {format(
                            new Date(schedule.dateOfVisit),
                            "dd MMM, yyyy"
                          )}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span
                          className={`text-sm font-medium ${colorStyle.bg} ${colorStyle.text} py-1 px-3 rounded-full`}
                        >
                          {`${formatTimeString(
                            schedule.startTime
                          )} - ${formatTimeString(schedule.endTime)}`}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {data.totalPages > 1 && (
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mt-4">
          <button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            disabled={page === 1 || isLoading}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50 transition-opacity duration-200 hover:bg-blue-600"
          >
            Previous
          </button>
          <div className="text-sm text-gray-600">
            Page {page} of {data.totalPages}
          </div>
          <button
            onClick={() => setPage((prev) => prev + 1)}
            disabled={page === data.totalPages || isLoading}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50 transition-opacity duration-200 hover:bg-blue-600"
          >
            Next
          </button>
        </div>
      )}
      <ScheduleDetailsModal
        schedule={selectedSchedule}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Schedules;
