import React from "react";
import { motion } from "framer-motion";
import { slideFromRight } from "../../animations/Animations";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/Logo.svg";

const Header: React.FC = () => {
  return (
    <header className="flex items-center justify-between px-6 py-4 border-b border-gray-300">
      <Link to="/login">
        <motion.img
          src={Logo}
          alt="syncai logo"
          initial="hidden"
          animate="visible"
          variants={slideFromRight}
        />
      </Link>

      <NavLink
        to="/pricing"
        className={({ isActive }) =>
          isActive
            ? "text-blue-600 capitalize font-bold"
            : "text-gray-800 capitalize font-bold"
        }
      >
        pricing
      </NavLink>
    </header>
  );
};

export default Header;
