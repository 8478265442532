import React from "react";

interface HeaderTextProps {
  text: string;
}

const HeaderText: React.FC<HeaderTextProps> = ({ text }) => {
  return (
    <h1 className="text-xl font-bold text-black mb-4 uppercase  md:text-2xl lg:text-4xl ">
      {text}
    </h1>
  );
};

export default HeaderText;
